import React from 'react';
import api from '../../config/api';
import firstLoginReducer from './FirstLoginReducer';

const FirstLoginContext = React.createContext();
export const FirstLoginLoadingContext = React.createContext();
export const FirstLoginErrorContext = React.createContext();
export const FirstLoginSuccessContext = React.createContext();
export const FirstLoginMessageContext = React.createContext();
export const FirstLoginSuccessResetContext = React.createContext();

export const FirstLoginProvider = ({ children }) => {

    // INITIAL STATE
    const initialState = {
        firstLoginLoading: false,
        firstLoginError: '',
        firstLoginSuccess: false,
        firstLoginMessage: ''
    };

    // REDUCER
    const [state, dispatch]  = React.useReducer(firstLoginReducer, initialState);

    // LOGIN
    const firstLoginFn = React.useCallback(async(email, password)=>{

        try{
            // console.log(email, password);
            // DISPATCH LOADING
            dispatch({type: 'LOGIN_LOADING', loading:true});
            
             // Confid
             let config ={
                headers:{
                    'Content-Type':'application/json',
                    // Authorizatin:`Bearer ${tkn}`
                }
            }
            // FETCH POST
            const res = await api.post('/auth/firstlogin', {
                email,
                password,
            },config);
            if(res?.status === 200){
                // console.log(res.data)
                
                // DISPATCH SUCCESS
                dispatch({type: 'LOGIN_SUCCESS', firstLoginSuccess: res?.data?.success});

                dispatch({type: 'LOGIN_MESSAGE', firstLoginMessage: res?.data?.message});
                
            }

        }catch(err){
            // console.log(err);
            if (err?.response) {
                // console.log(err.response);
                //  console.log(err.response.data);
                //  console.log(err?.response?.data?.message)
                // console.log(err.response.status);
                // console.log(err.response.headers);
                dispatch({type: 'LOGIN_ERROR', error: err?.response?.data?.message});
            } else {
                console.log(err?.message);
                dispatch({type: 'LOGIN_ERROR', error: err?.message});
            }
        }
        finally{
            dispatch({type: 'LOGIN_LOADING', loading:false});
            setTimeout(()=>{
                // dispatch({type: 'LOGIN_SUCCESS', firstLoginSuccess: false});
                dispatch({type: 'LOGIN_ERROR', error: ''});
                dispatch({type:'LOGIN_MESSAGE', firstLoginMessage:''})
            }, 3000);
        };
    },[]);

    function firstLoginSuccessResetFn (){
            dispatch({type: 'LOGIN_SUCCESS', firstLoginSuccess: false});
    };

    // RETURN 
    return(
        <FirstLoginContext.Provider value={firstLoginFn}>
           <FirstLoginSuccessResetContext.Provider value={firstLoginSuccessResetFn}>
            <FirstLoginLoadingContext.Provider value={state.firstLoginLoading}>
                <FirstLoginErrorContext.Provider value={state.firstLoginError}>
                    <FirstLoginSuccessContext.Provider value={state.firstLoginSuccess}>
                        <FirstLoginMessageContext.Provider value={state.firstLoginMessage}>
                            {children}
                        </FirstLoginMessageContext.Provider>
                    </FirstLoginSuccessContext.Provider>
                </FirstLoginErrorContext.Provider>
            </FirstLoginLoadingContext.Provider>
           </FirstLoginSuccessResetContext.Provider>
        </FirstLoginContext.Provider>
    )
}

export default FirstLoginContext;
