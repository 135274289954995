
const pswUpdateRecuder = (state, action) => {

    switch (action.type) {
    
    case 'PSW_UPDATE_LOADING':
        return {
            ...state, pswUpdateLoading: action.loading
        };
    case 'PSW_UPDATE_ERROR':
        return {
            ...state, pswUpdateError: action.error
        };
    case 'PSW_UPDATE_DATA':
        return {
            ...state, pswUpdateData: action.data
        };
    // RESET    
    case 'PSW_UPDATE_RESET':
        return {
            ...action.payload
        };
    
    // DEFAULT
    default:
        return state;
    }
};

export default pswUpdateRecuder;