import React from 'react';
import './Reset.css';
import { useNavigate } from 'react-router-dom';
import { BsHourglassSplit } from 'react-icons/bs';
import sanitizeInput from '../hooks/sanitizeInput';
import { UserInContext } from '../context/LogoutContextReducer/LogoutContext';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ResetContext, { ResetLoadingContext, ResetErrorContext, ResetMessageContext, ResetSuccessContext } from '../context/ResetContextReducer/ResetContext';

const Reset  = () => {
    const {email} = useParams();
    const [userEmail, setUserEmail] = React.useState(email || '');
    const [password, setPassword] = React.useState('');

    // HISTORY
    const history = useNavigate();

    // RESET CONTEXT 
    const resetFn = React.useContext(ResetContext);
    const resetLoading = React.useContext(ResetLoadingContext);
    const resetError = React.useContext(ResetErrorContext);
    const resetSuccess = React.useContext(ResetSuccessContext);
    const resetMessage = React.useContext(ResetMessageContext);

    // USER IN CONTEXT
    const usrIn = React.useContext(UserInContext);

    // CHECK IF USER IS LOGGED IN
    React.useEffect(() => {
        if (!usrIn) {
            history('/');
        }
    }, [usrIn, history]);

    // HANDLE SUBMIT
    const handleSubmit = React.useCallback(async (e) => {
        e.preventDefault();
        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(userEmail) === false) { // eslint-disable-line
            toast.error('Invalid Email.');
            return;
        };

        try {
            await resetFn(userEmail, password);

        } catch (err) {
            toast.error(err?.message);
        }
    }, [resetFn, userEmail, password]);


    // REACT USE EFFECT
    React.useEffect(() => {
        if (resetError !== '') {
            // console.log(loginError.message)
            toast.error(resetError);
        }
    }, [resetError]);

    // REACT USE EFFECT
    React.useEffect(() => {
        // console.log('success',resetSuccess);
        // console.log('success',resetMessage);

        if (resetSuccess && resetMessage !== '') {
            // console.log('success',resetSuccess);
            // console.log('success',resetMessage);

            toast(resetMessage);
            
            setTimeout(() => {
                history(`/profile`);
            }, 1000);
            setUserEmail('');
            setPassword('');
        }

        if (!resetSuccess && resetMessage !== '') {
            // console.log('success',resetSuccess);
            // console.log('success',resetMessage);
            toast(resetMessage);
        }

        // eslint-disable-next-line
    }, [resetSuccess, resetMessage]);

    // FORM REF
    const formRef = React.useRef(null);
    // console.log(formRef)

    // RETURN 
    return (
        <div className='Reset'>
            <h1>Reset Password</h1>

            <form ref={formRef} autoComplete='off' onSubmit={handleSubmit}>

                <div className="reset-loading">
                    {resetLoading && <BsHourglassSplit />}
                </div>

                <div className="email">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        placeholder='Email'
                        autoFocus
                        title='Insert email'
                        id='email'
                        name='email'
                        defaultValue={userEmail}
                        maxLength={50}
                        readOnly
                       // required
                    />
                </div>


                <div className="password">
                <label htmlFor="password">Password</label>
                    <input
                        type="text"
                        placeholder='Password'
                        title='Insert password'
                        id='password'
                        name='password'
                        value={password}
                        maxLength={50}
                        // minLength={6}
                        onChange={e => setPassword(sanitizeInput(e.target.value))}
                    // required
                    />
                </div>

                <button className='btnSubmit'>
                    Reset
                </button>
            </form>
        </div>
    )
}
export default Reset;
