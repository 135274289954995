
const pswDeleteReducer = (state, action) =>{

    switch(action.type){
        case 'PSW_DELETE_LOADING':
            return {
                ...state, pswDeleteLoading: action.loading
            }
        case 'PSW_DELETE_ERROR':
            return {
                ...state, pswDeleteError: action.error
            }
        case 'PSW_DELETE_SUCCESS':
            return {
                ...state, pswDeleteSuccess: action.success
            }

        // DEFAULT
        default:
            return state
    }
};

export default pswDeleteReducer;