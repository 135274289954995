import React from 'react';
import Cookies from 'universal-cookie';
import api from '../../config/api';
import {UserInDispatchContext} from '../LogoutContextReducer/LogoutContext';
import loginReducer from './LoginReducer';

const LoginContext = React.createContext();
export const LoginLoadingContext = React.createContext();
export const LoginErrorContext = React.createContext();
export const LoginSuccessContext = React.createContext();

export const LoginProvider = ({ children }) => {

    // INITIAL STATE
    const initialState = {
        loginLoading: false,
        loginError: '',
        loginSuccess: '',
    };

    // REDUCER
    const [state, dispatch]  = React.useReducer(loginReducer, initialState);


    // CONTEXT
    const userInFn = React.useContext(UserInDispatchContext);


    // LOGIN
    const loginFn = React.useCallback(async(email, otp)=>{

        try{
            // COOKIE HOOK
            const cookies = new Cookies();
            //  console.log(email, otp);
            // DISPATCH LOADING
            dispatch({type: 'LOGIN_LOADING', loading:true});
            
             // Confid
             let config ={
                headers:{
                    'Content-Type':'application/json',
                    // Authorizatin:`Bearer ${tkn}`
                }
            }
            // FETCH POST
            const res = await api.post('/auth/login', {
                email,
                otp,
            },config);
            // console.log(res)
            if(res?.status === 200){
                
                // SET COOKIE
                cookies.set('tkn', res?.data?.tkn, { path: '/'});
                // DISPATCH SUCCESS
                dispatch({type: 'LOGIN_SUCCESS', loginSuccess: res?.data?.message});
                // DIISPATCH USER
                await userInFn(res?.data?.usr);
            }

        }catch(err){
            // console.log(err);
            if (err?.response) {
                // console.log(err.response);
                //  console.log(err.response.data);
                //  console.log(err?.response?.data?.message)
                // console.log(err.response.status);
                // console.log(err.response.headers);
                dispatch({type: 'LOGIN_ERROR', error: err?.response?.data?.message});
            } else {
                console.log(err?.message);
                dispatch({type: 'LOGIN_ERROR', error: err?.message});
            }
        }
        finally{
            dispatch({type: 'LOGIN_LOADING', loading:false});
            setTimeout(()=>{
                dispatch({type: 'LOGIN_SUCCESS', loginSuccess: ''});
                dispatch({type: 'LOGIN_ERROR', error: ''});
            }, 3000);
        };
    },[dispatch,userInFn]);

    // RETURN 
    return(
        <LoginContext.Provider value={loginFn}>
            <LoginLoadingContext.Provider value={state.loginLoading}>
                <LoginErrorContext.Provider value={state.loginError}>
                    <LoginSuccessContext.Provider value={state.loginSuccess}>
        {children}
                    </LoginSuccessContext.Provider>
                </LoginErrorContext.Provider>
            </LoginLoadingContext.Provider>
        </LoginContext.Provider>
    )
}

export default LoginContext;
