import React from 'react';
import Header from './components/Header.jsx';
import Home from './components/Home';
import Search from './components/Search';
import Footer from './components/Footer';
import Nav from './components/Nav';
import About from './components/About';
import NewPsw from './components/NewPsw';
import Missing from './components/Missing';
import PswPage from './components/PswPage';
// import Register from './components/Register.jsx';
// import RegistrationCode from './components/RegistrationCode';
import Login from './components/Login.jsx';
import Random from './components/Random';
import Backup from './components/Backup';
import Profile from './components/Profile';
// import Cookies from 'universal-cookie';
import { Route, Routes } from 'react-router-dom';
// CONTEXT
import { SearchData } from './context/SearchContextReducer/SearchContext';
import { PswsGetPageData, PswsGetPageLoading, PswsGetPageError } from './context/PswsGetPageContextReducer/PswsGetPageContext';
import { toast } from 'react-toastify';
import FirstLogin from './components/FirstLogin.jsx';
import Reset from './components/Reset.jsx';


function App() {

  // CONTEXT PSWS GET PAGE
  // const pswsGetPageFn = React.useContext(PswsGetPageContext);
  const pswsGetPageLoading = React.useContext(PswsGetPageLoading);
  const pswsGetPageError = React.useContext(PswsGetPageError);
  const pswsGetPageData = React.useContext(PswsGetPageData);


  // COOKIE HOOK
  // const cookies = new Cookies();


  // CONTEXT SEARCH 
  const searchData = React.useContext(SearchData);

  // CONTEXT
  // const pswsGetAllLoading = React.useContext(PswsGetAllLoading);


  // React.useEffect(() => {
  //   // console.log('Rendering...')
  //   let tkn = cookies.get('tkn');

  //   if (tkn !== undefined) {
  //     // console.log('Fetching');
  //     (async () => await pswsGetPageFn())();
  //   };

  //   // eslint-disable-next-line
  // }, []);


  // USE EFFECT ERROR
  React.useEffect(() => {

    if (pswsGetPageError) {
      toast.error('Error: ' + pswsGetPageError);
    }

  }, [pswsGetPageError]);

  // RETURN 
  return (
    <div className="App">
      {pswsGetPageLoading ?
        <div className="lds-ripple"><div></div><div></div></div>
        :
        <>

          <Header title={'Pa Man'} />
          <Nav />

          <Routes>
            <Route path='/' element={<Home pswsGetPageData={pswsGetPageData} />} />

            <Route path='/search' element={<Search searchData={searchData} />} />

            <Route path='/about' element={<About />} />

            <Route path='/newpsw' element={<NewPsw />} />

            <Route path='/pswpage/:id' element={<PswPage />} />

            <Route path='/random' element={<Random />} />

            <Route path='/backup' element={<Backup />} />

            <Route path='/auth/login/:email' element={<Login />} />
            
            <Route path='/auth/firstlogin' element={<FirstLogin />} />

            <Route path='/auth/reset/:email' element={<Reset  />} />

            {/* <Route path='/auth/register' element={<Register />} />

            <Route path='/auth/registration' element={<RegistrationCode />} /> */}

            <Route path='/profile' element={<Profile />} />

            <Route path='*' element={<Missing />} />

          </Routes>

          <Footer />
        </>
      }

    </div>
  );
}

export default App;
