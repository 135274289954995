import React from 'react';
import Cookies from 'universal-cookie';
import api from '../../config/api';
import PswsGetPageContext, { PswsGetPageReset} from '../PswsGetPageContextReducer/PswsGetPageContext';
import {PswsGetAllResetData} from '../PswsGetAllContextReducer/PswsGetAllContext';
import logoutReducer from './LogoutReducer';
import { SearchResetData } from '../SearchContextReducer/SearchContext';

const LogoutContext = React.createContext();
export const LogoutSuccessContext = React.createContext();
export const LogoutErrorContext = React.createContext();
export const UserInContext = React.createContext();
export const UserInDispatchContext = React.createContext();

export const LogoutProvider = ({ children }) => {


    // INITIAL STATE
    const initialState = {
        logoutLoading: false,
        logoutSuccess:'',
        logoutError:'',
        usrIn:null,
    };


    // REDUCER
    const [state, dispatch] = React.useReducer(logoutReducer, initialState);

    // CONTEXT PSWS GET PAGE
    const pswsGetPageResetStateFn = React.useContext(PswsGetPageReset);
   

    // CONTEXT PSWS GET ALL
    const pswsGetAllResetFn = React.useContext(PswsGetAllResetData);

    // CONTEXT PSWS GET PAGE
    const fetchPsws = React.useContext(PswsGetPageContext);
   

    // CONTEXT SEARCH RESET DATA
    const resetSearchData = React.useContext(SearchResetData);

     // LOG OUT
     const logoutFn = React.useCallback(async ()=>{
        try{
            // COOKIE HOOK
            const cookies = new Cookies();

            // DISPATCH LOADING
            dispatch({type:'LOGOUT_LOADING', loading: true});
            
            // REMOVE COOKIE
            cookies.remove('tkn',{ path: '/' });

            // DISPATCH USER IN NULL
            dispatch({type: 'USRIN_STATE', usrIn: null});

            // RESET STATE
            pswsGetAllResetFn();
            pswsGetPageResetStateFn();
            // DISPATCH RESET SEARCH STATE
            resetSearchData();
            
            // DISPATCH LOGOUT SUCCESS
            dispatch({type:'LOGOUT_SUCCESS', success: 'Logged Out.'});
 
        }catch(err){
         if (err?.response) {
             // console.log(err.response);
             // console.log(err.response.data);
             // DISPATCH ERROR
             dispatch({type:'LOGOUT_ERROR', error: err?.response?.data.message})
             // console.log(err.response.status);
             // console.log(err.response.headers);
         } else {
             console.log(err?.message);
             dispatch({type:'LOGOUT_ERROR', error: err?.message});
         }
     }
     finally{
         setTimeout(()=>{
             // DISPATCH LOADING
            dispatch({type:'LOGOUT_LOADING', loading: false});
            dispatch({type:'LOGOUT_ERROR', error: ''});
            dispatch({type: 'LOGOUT_SUCCESS', success: ''});
            dispatch({type: 'USRIN_STATE', usrIn: null});
         }, 3000);
     };
}, [pswsGetPageResetStateFn, pswsGetAllResetFn, resetSearchData, dispatch]);


// IS IN
const isInRefreschFn = React.useCallback(async ()=>{
    // console.log('check in');
    try{
        // COOKIE HOOK
        const cookies = new Cookies();

        // GET TOKEN
        let tkn = cookies.get('tkn');

        if(tkn === undefined) return;

        if(tkn !== undefined){
            // Config
            let config = {
                headers:{
                    'Content-Type':'application/json',
                    Authorization: `Bearer ${tkn}`
                }
            }
            const res = await api.get('/auth/isin', config);
            //  console.log(res);
            if(res?.status === 200){
                // console.log(res.data);
                // DISPATCH USER
                 dispatch({type:'USRIN_STATE', usrIn: res?.data});
            }else{
                // console.log('Haaa!!!');
                logoutFn();
            }

        }
    }catch(err){
        if (err.response) {
            // console.log(err.response);
            // console.log(err.response.data);
            dispatch({type:'LOGOUT_ERROR', error: err?.response?.data.message})
            // console.log(err.response.status);
            // console.log(err.response.headers);
        } else {
            console.log(err.message);
            dispatch({type:'LOGOUT_ERROR', error: err?.message})
        }
       logoutFn();
    }finally{
        setTimeout(()=>{
            dispatch({type:'LOGOUT_ERROR', error: ''});
            dispatch({type: 'LOGOUT_SUCCESS', success: ''});
         }, 3000);
    }
}, [logoutFn, dispatch]);


 // USE EFFECT
 React.useEffect(()=>{
    // COOKIE HOOK
    const cookies = new Cookies();
    
    let tkn = cookies.get('tkn');
        
    if (tkn !== undefined && state.usrIn !== null) {
            (async ()=> await fetchPsws())();
        };

    // eslint-disable-next-line
}, [state.usrIn]);

React.useEffect(()=>{
    // COOKIE HOOK
    const cookies = new Cookies();
    
    let tkn = cookies.get('tkn');
        
    if (tkn) {
            (async ()=> await isInRefreschFn())();
        };

    // eslint-disable-next-line
}, []);

// DISPATCH USER IN
const userInFn = React.useCallback(async (usr)=>{
    dispatch({type:'USRIN_STATE', usrIn: usr});
},[dispatch]);

 
    // RETURN
    return (
        <LogoutContext.Provider value={logoutFn}>
            <LogoutSuccessContext.Provider value={state.logoutSuccess}>
                <LogoutErrorContext.Provider value={state.logoutError}>
                    <UserInContext.Provider value={state.usrIn}>
                            <UserInDispatchContext.Provider value={userInFn}>
                                {children}
                            </UserInDispatchContext.Provider>
                    </UserInContext.Provider>
              </LogoutErrorContext.Provider>
            </LogoutSuccessContext.Provider>
        </LogoutContext.Provider>
    );
};

export default LogoutContext;