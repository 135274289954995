
const resetReducer = (state, action) =>{
    switch(action.type){
        case 'RESET_LOADING':
            return {
                ...state, resetLoading: action.loading
            }
        case 'RESET_ERROR':
            return {
                ...state, resetError: action.error
            }
        case 'RESET_SUCCESS':
            return {
                ...state, resetSuccess: action.resetSuccess 
            }
        case 'RESET_MESSAGE':
        return {
            ...state, resetMessage: action.resetMessage 
        }

        // DEFAULT
        default:
            return state;
    }
};

export default resetReducer;