import React from 'react';
import Feed from './Feed';
import './Home.css';

const Home = ({ pswsGetPageData }) => {

  // RETURN
  return (
    <main className='Home'>
      <Feed pswsGetPageData={pswsGetPageData} />
    </main>
  )
}

export default Home;
