import React from 'react';
import './OtpInput.css';

const OtpInput = ({ value, onChange, active }) => {

    // Ref
    const ref = React.useRef(null);

    React.useEffect(() => {
        if (ref && ref.current) {
            if (active) {
                ref.current.focus()
            }
            else {
                ref.current.blur()
            }
        }
    }, [active]);

    // RETURN
    return (
        <div className='Otpinput'>
            <input
                ref={ref}
                type="tel"
                inputMode="numeric"
                autoComplete='one-time-code'
                pattern='\d{1}'
                maxLength={1}
                className='otp-input'
                value={value}
                placeholder={''}
                onChange={e => onChange(e.target.value)}
                autoFocus={active}
            />
        </div>
    )
};

export default OtpInput;