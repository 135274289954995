
const searchReducer = (state, action)=>{

    switch(action.type){
        case 'SEARCH_LOADING':
            return {
                ...state, searchLoading: action.loading
            }
        case 'SEARCH_ERROR':
            return {
                ...state, searchError: action.error
            }
        case  'SEARCH_SET':
            return {
                ...state, search: action.search
            }

        case 'SEARCH_DATA':
            return {
                ...state, searchData: action.data
            }
        case 'RESET_SEARCH_DATA':
            return {
                ...state, searchData: action.searchData
            }
        
        // DEFAULT
        default:
            return state;
    }

};

export default searchReducer;