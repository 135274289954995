import React from 'react';
import api from '../../config/api';
import Cookies from 'universal-cookie';
import BckDownloadReducer from './BckDownloadReducer';
import fileDownload from 'js-file-download';

const BckDownloadContext = React.createContext(()=>{});
export const BckDownloadLoading = React.createContext(false);
export const BckDownloadError = React.createContext('');
export const BckDownloadData = React.createContext({});
export const BckDownloadReset = React.createContext(()=>{});


export const BckDownloadProvider = ({children})=>{

 // INITIAL STATE
 const initialState = React.useMemo(() => {
    return {
    bckDownloadLoading: false,
    bckDownloadError: '',
    bckDownloadData: [],
    };
},[]);


// REDUCER
const [state, dispatch] = React.useReducer(BckDownloadReducer, initialState);


// FETCH BCK
const bckDownloadFn = React.useCallback( async () => {
    
    // GET COOKIE
    const cookies = new Cookies();  

    // TOKEN
    let tkn = cookies.get('tkn');
    // console.log(tkn);
    if(tkn === undefined) return;

    try {
        if(tkn){
            
               // GET COOKIE
               const cookies = new Cookies();

               // DISPATCH LOADING
                dispatch({type: 'BCK_DOWNLOAD_LOADING', loading: true}); 
               
                // TKN
               let tkn = cookies.get('tkn');
   
               if(!tkn) return;

            // Config
            let config = {
                headers:{
                    mode: "no-cors",
                    cache: 'no-cache',
                    credentials:"same-origin",
                    'Content-Type':'application/json',
                    Authorization: `Bearer ${tkn}`
                },
                responseType: 'blob', // must be type blob
            };


            
            const res = await api.get(`/bck/downloadbck`,config);
            // console.log(res);
            fileDownload(res.data, 'Backup.json');
            
            // DISPATCH DATA
            // dispatch({type: 'BCK_DOWNLOAD_DATA', data: res});
        };

    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.error);
            // console.log(err?.response?.status);
            // console.log(err?.response?.headers);
            
            // DISPATCH ERROR
            dispatch({type: 'BCK_DOWNLOAD_ERROR', error: err?.response?.data?.error === "jwt malformed" ? '' : err?.response?.data?.error});

        } else {
            console.log(err.message);
            dispatch({type: 'BCK_DOWNLOAD_ERROR', error: err?.message});
        }
    } finally {
        // DISPATCH LOADING
        dispatch({type: 'BCK_DOWNLOAD_LOADING', loading: false});

        setTimeout(()=>{
            dispatch({type: 'BCK_DOWNLOAD_ERROR', error: ''});
        }, 3000);
    }
}, [dispatch]);


    
    // PSWS GET ALL RESET
    const bckDownloadResetFn = React.useCallback( () => {
        dispatch({type: 'BCK_DOWNLOAD_RESET', reset: initialState});
    }, [dispatch, initialState]);

    return(

        <BckDownloadContext.Provider value={bckDownloadFn}>
            <BckDownloadLoading.Provider value={state.bckDownloadLoading}>
                <BckDownloadError.Provider value={state.bckDownloadError}>
                  <BckDownloadData.Provider value={state.bckDownloadData}>
                   <BckDownloadReset.Provider value={bckDownloadResetFn}>

                                    {children}

                            </BckDownloadReset.Provider>
                    </BckDownloadData.Provider>
                </BckDownloadError.Provider>
            </BckDownloadLoading.Provider>
        </BckDownloadContext.Provider>
    
    
        
        )
};

export default BckDownloadContext;

