import React from 'react';
import api from '../../config/api.js';
import Cookies from 'universal-cookie';
import pswNewReducer from './PswNewReducer.js';

const PswNewContext = React.createContext({});
export const PswNewLoading = React.createContext(false);
export const PswNewError = React.createContext('');
export const PswNewData = React.createContext();
export const PswNewReset = React.createContext(null);

export const PswNewProvider = ({ children }) => {

    // INITIAL STATE
    const initialState = React.useMemo(() => {
       return { 
        pswNewLoading: false,
        pswNewError: '',
        pswNewData: null,
        };
    },[]);

   // REDUCER
    const [state, dispatch] = React.useReducer(pswNewReducer, initialState);
    

       // PSW CREATE
       const pswNewFn = React.useCallback(async newPsw =>{
        try{
            // GET COOKIE
            const cookies = new Cookies();
            // DISPATCH LOADING
            dispatch({type:'PSW_NEW_LOADING', loading: true});
           
            // TKN
            let tkn = cookies.get('tkn');
            
            if(!tkn)return;

            let config = {
                headers:{
                    'Content-Type':'application/json',
                    Authorization: `Bearer ${tkn}`
                }
            }
            
            const res = await api.post(`api/psws/create`, newPsw, config);
            // DISPATCH DATA
            dispatch({type:'PSW_NEW_DATA', data: res.data});

        }catch(err){

         if (err?.response) {
                console.log(err?.response?.data);
                console.log(err?.response?.status);
                console.log(err?.response?.headers);
                // DISPATCH ERROR
                dispatch({type:'PSW_NEW_ERROR', error: err?.response?.data});
                
            } else {
                console.log(err?.message);
                // DISPATCH ERROR
                dispatch({type:'PSW_NEW_ERROR', error: err?.message});
            }          
        }finally{
            setTimeout(()=>{
                dispatch({type:'PSW_NEW_LOADING', loading: false});
                dispatch({type:'PSW_NEW_ERROR', error: ''});
            },3000);
        }
    },[dispatch]);

    // PSW NEW RESET
    const pswNewResetFn = React.useCallback(() =>{
        dispatch({type:'PSW_NEW_RESET', reset: initialState});
    },[dispatch, initialState]);

    // RETURN
    return (
        <PswNewContext.Provider value={pswNewFn}>
         <PswNewLoading.Provider value={state.pswNewLoading}>
          <PswNewError.Provider value={state.pswNewError}>
           <PswNewData.Provider value={state.pswNewData}>
            <PswNewReset.Provider value={pswNewResetFn}>
                {children}
            </PswNewReset.Provider>
           </PswNewData.Provider>
          </PswNewError.Provider>
         </PswNewLoading.Provider>
        </PswNewContext.Provider>
    );
};

export default PswNewContext;