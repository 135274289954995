import React from 'react';
import Psw from './Psw';
import './Feed.css';
import DarkContext from '../context/DarkContextReducer/DarkContext';
import { SearchKey } from '../context/SearchContextReducer/SearchContext';

import {PswsGetPagePage, PswsGetPageLastPage, PswsGetPageSetPage} from '../context/PswsGetPageContextReducer/PswsGetPageContext';

const Feed = ({  pswsGetPageData }) => {

  // CONTEXT PSWS GET PAGE
  const page = React.useContext(PswsGetPagePage);
  const lastPage = React.useContext(PswsGetPageLastPage);
  const pswsGetPageSetPageFn = React.useContext(PswsGetPageSetPage);

  // PAGES CUT COUNT, HOW MANY PAGES NUMBER TO DISPLAY
  const pagesCutCount = 6;


  // CONTEXT SEARCH
  const  search  = React.useContext(SearchKey);

  // CONTEXT DARK
  const isDark = React.useContext(DarkContext);
  // console.log(isDark);

  // HANDLE FIRST PAGE
  const handleFirstPage = ()=>{
    pswsGetPageSetPageFn(1);
  };

  // HANDLE PREV
  const handlePrev = () => {
    console.log('prev')
    pswsGetPageSetPageFn(page - 1);
  }

  // HANDLE LAST PAGE
  const handleLastPage = ()=>{
    pswsGetPageSetPageFn(+lastPage);
  };

  // HANDLE NEXT
  const handleNext = () => {
    console.log('next');
    pswsGetPageSetPageFn(page + 1);
  }
  const handlePagNumb = num => {
    pswsGetPageSetPageFn(num);
  };

  // RANGE FUNCTION
  const range = (start,end)=>{
    let arr = [];
    for(let i = start; i <= end; i++){
      arr.push(i);
    };
    return arr;
  };

  // HANDLE PAGE CUT
  const getPagesCut = ({lastPage, pagesCutCount, page})=>{
        const ceiling = Math.ceil(pagesCutCount / 2);
        const floor = Math.floor(pagesCutCount / 2);

        if(+lastPage < +pagesCutCount){
          return {start:1, end:+lastPage};
        }else if(+page >= 1 && +page <= +ceiling){
          return {start:1, end:pagesCutCount}
        }else if(+page + +floor >= +lastPage){
          return {start: +lastPage - +pagesCutCount, end: +lastPage};
        }else{
          return {start:+page - +ceiling, end: +page + +floor};
        };
  };
  const pagesCut = getPagesCut({lastPage, pagesCutCount, page});
  const pagesRange = range(pagesCut.start, pagesCut.end);

  // RETURN 
  return (
    <div className='Feed'>

      {
        pswsGetPageData && pswsGetPageData.map(p => (
          <Psw key={p.id} p={p} />
        ))
      }
      {
        pswsGetPageData.length === 0 && <div className={isDark ?'landing': 'landing isDark'}>
          {/* <img src="/imgs/front.jpg" alt="front" /> */}
        </div>
      }

      {/* PAGINATION  */}
      {!search &&
        <div className='pagination'>

        {
            page > 1 && (
              <div className='first'>
                <button onClick={handleFirstPage}>
                  <img src="/imgs/chevronLeft.svg" alt="left" /> First
                </button>
              </div>
            )
          }


          {
            page > 1 && (
              <div className='prev'>
                <button onClick={handlePrev}>
                  <img src="/imgs/chevronLeft.svg" alt="left" /> Prev
                </button>
              </div>
            )
          }

          {
            pagesRange.map(i => (
              <span key={i} className={i === page ? 'pageNumber pageActive' : 'pageNumber'} onClick={() => handlePagNumb(i)}>
                {i}
              </span>
            ))
          }

          {/* NEXT  */}
          
          {
            page < lastPage && (
              <div className='next'>
                <button onClick={handleNext}>
                 Next <img src="/imgs/chevronRight.svg" alt="right" />
                </button>
              </div>
            )
          }

{
            page < lastPage && (
              <div className='last'>
                <button onClick={handleLastPage}>
                  Last <img src="/imgs/chevronRight.svg" alt="right" />
                </button>
              </div>
            )
          }
        </div>
      }

    </div>
  )
}

export default Feed;
