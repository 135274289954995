import React from 'react';
import api from '../../config/api.js';
import Cookies from 'universal-cookie';
import pswsGetPageReducer from './PswsGetPageReducer.js';
import {jwtDecode as jwt_decode} from 'jwt-decode';

const PswsGetPageContext = React.createContext();
export const PswsGetPageLoading = React.createContext(false);
export const PswsGetPageError = React.createContext(null);
export const PswsGetPageData = React.createContext([]);
// PAGINATION DATA
export const PswsGetPageTotal = React.createContext(0);
export const PswsGetPagePerPage = React.createContext(3);
export const PswsGetPageLastPage = React.createContext(0);
export const PswsGetPagePage = React.createContext(0);
export const PswsGetPageSetPage = React.createContext();
export const PswsGetPageReset = React.createContext();


export const PswsGetPageProvider = ({children}) =>{

    // INITIAL STATE
    const initialState = {
        pswsGetPageLoading: false,
        pswsGetPageError: '',
        pswsGetPageData: [],
        total: 0,
        perPage: 3,
        lastPage: 0,
        page: 1,
    };

    // REDUCER
    const [state, dispatch] = React.useReducer(pswsGetPageReducer, initialState);

    // RESET STATE FUNCTION
    const pswsGetPageResetStateFn = () =>{
        dispatch({type: 'PSWS_GET_PAGE_RESET', payload: initialState});
    };

     // FETCH PSWORDS
     const pswsGetPageFn = React.useCallback(async (page) => {
        // GET COOKIE
        const cookies = new Cookies();
         // TOKEN
         let tkn = cookies.get('tkn');
        //  console.log(tkn);
          if(tkn === undefined) return;

          if(tkn){
            let dec = jwt_decode(tkn);
            let expiration = (dec?.exp * 1000) - 60000;
            if(Date.now() >= (new Date(expiration).getTime())){
                // console.log('Expired')
                return;
            }
          }
        
        try {

                // SET LOADING
                dispatch({type: 'PSWS_GET_PAGE_LOADING', loading: true});
    
                if(page === undefined) page = 1;
    
                // Config
                let config = {
                    headers:{
                        'Content-Type':'application/json',
                        Authorization: `Bearer ${tkn}`
                    }
                }
    
                const res = await api.get(`/api/psws?page=${page}`, config);
                // console.log(res.data);
                
                // SET DATA
                dispatch({type: 'PSWS_GET_PAGE_DATA', data: res?.data?.arrSorted});
                
                // DISPATCH TOTAL PAGES
                dispatch({type: 'PSWS_GET_PAGE_TOTAL', total: res?.data?.total});
                // DISPATCH PER PAGE
                dispatch({type: 'PSWS_GET_PAGE_PER_PAGE', perPage: res?.data?.perPage});
                // DISPATCH LAST PAGE
                const lastPage = Math.ceil(res.data.total / res?.data?.perPage);
                dispatch({type: 'PSWS_GET_PAGE_LAST_PAGE', lastPage: lastPage});
                // DISPATCH PAGE
                dispatch({type: 'PSWS_GET_PAGE_PAGE', page: +res?.data?.page});

        } catch (err) {
            if (err?.response) {
                console.log(err?.response?.data);
                // console.log(err?.response?.status);
                // console.log(err?.response?.headers);
                // DISPATCH ERROR
                dispatch({type: 'PSWS_GET_PAGE_ERROR', error: err?.response?.data?.error === "jwt malformed" ? '' : err?.response?.data?.error});
                
            } else {
                console.log(err?.message);
                // DISPATCH ERROR
                dispatch({type: 'PSWS_GET_PAGE_ERROR', error: err?.message});
            }
        } finally {
            // SET LOADING
            dispatch({type: 'PSWS_GET_PAGE_LOADING', loading: false});
            
            setTimeout(()=>{
                // RESET ERROR
                dispatch({type: 'PSWS_GET_PAGE_ERROR', error: ''});
            }, 3000);
        };
    }, [dispatch]);

      // USE EFFECT FETCH ALL PSWS ON PAGE CHANGE
      React.useEffect(() => {
        
        (async () => pswsGetPageFn(state.page))();
        
        // eslint-disable-next-line 
    }, [state.page]);


    // DISPATCH SET PAGE
    const pswsGetPageSetPageFn = React.useCallback((page) => {
        dispatch({type: 'PSWS_GET_PAGE_SET_PAGE', page: +page});
    }, [dispatch]);
 
    // Return
    return (
        <PswsGetPageContext.Provider value={pswsGetPageFn}>
            <PswsGetPageLoading.Provider value={state.pswsGetPageLoading}>
                <PswsGetPageError.Provider value={state.pswsGetPageError}>
                    <PswsGetPageData.Provider value={state.pswsGetPageData}>
                        <PswsGetPageTotal.Provider value={state.total}>
                            <PswsGetPagePerPage.Provider value={state.perPage}>
                                <PswsGetPageLastPage.Provider value={state.lastPage}>
                                    <PswsGetPagePage.Provider value={state.page}>
                                         <PswsGetPageSetPage.Provider value={pswsGetPageSetPageFn}>
                                            <PswsGetPageReset.Provider value={pswsGetPageResetStateFn}>
                                                    {children}
                                            </PswsGetPageReset.Provider>
                                         </PswsGetPageSetPage.Provider>
                                    </PswsGetPagePage.Provider>
                                </PswsGetPageLastPage.Provider>
                            </PswsGetPagePerPage.Provider>
                        </PswsGetPageTotal.Provider>
                    </PswsGetPageData.Provider>
                </PswsGetPageError.Provider>
            </PswsGetPageLoading.Provider>
        </PswsGetPageContext.Provider>
    )

}

export default PswsGetPageContext;