
const firstLoginReducer = (state, action) =>{
    switch(action.type){
        case 'LOGIN_LOADING':
            return {
                ...state, firstLoginLoading: action.loading
            }
        case 'LOGIN_ERROR':
            return {
                ...state, firstLoginError: action.error
            }
        case 'LOGIN_SUCCESS':
            return {
                ...state, firstLoginSuccess: action.firstLoginSuccess 
            }
        case 'LOGIN_MESSAGE':
        return {
            ...state, firstLoginMessage: action.firstLoginMessage 
        }

        // DEFAULT
        default:
            return state;
    }
};

export default firstLoginReducer;