
const registerReducer = (state, action)=> {

    switch(action.type){
        case 'REGISTER_LOADING':
            return {
                ...state, registerLoading : action.loading
            }
        case 'REGISTER_SUCCESS':
            return {
                ...state, registerSuccess : action.success, registerUser:action.user
            }
        case 'REGISTER_RESET':
            return{
                ...state, registerUser:action.user
            }
        case 'REGISTER_ERROR':
            return {
                ...state, registerError : action.error
            }

    // DEFAULT
    default:
        return state;
    };
}

export default registerReducer;