import React from 'react';
import api from '../../config/api.js';
import Cookies from 'universal-cookie';
import pswUpdateRecuder from './PswUpdateReducer.js';

const PswUpdateContext = React.createContext({});
export const PswUpdateLoading = React.createContext(false);
export const PswUpdateError = React.createContext('');
export const PswUpdateData = React.createContext(null);


export const PswUpdateProvider = ({ children }) => {
    
    // INITIAL STATE
    const initialState = React.useMemo(() => {
       return { pswUpdateLoading: false,
        pswUpdateError: '',
        pswUpdateData:  null,
        };
    },[]);
    
    // REDUCER
    const [state, dispatch] = React.useReducer(pswUpdateRecuder, initialState);
    

    // RESET STATE
    const resetState = React.useCallback(() => {
        dispatch({type: 'PSW_UPDATE_RESET', payload: initialState});
    }, [dispatch, initialState]);


      // UPDATE
      const pswUpdateFn = React.useCallback(async (id,pswUpdated) =>{
        try{
            // GET COOKIE
            const cookies = new Cookies();
            // TKN
            let tkn = cookies.get('tkn');
            
            if(!tkn) return;

            // DISPATCH LOADING
            dispatch({type:'PSW_UPDATE_LOADING', loading: true});
            

           let config = {
               headers:{
                   'Content-Type':'application/json',
                   Authorization: `Bearer ${tkn}`
               }
           };

            const res = await api.put(`api/psws/update/${id}`, pswUpdated, config);
            // console.log(res.data)
            // DISPATCH DATA
            dispatch({type:'PSW_UPDATE_DATA', data: res?.data});

        }catch(err){ 
            if (err?.response) {
                console.log(err?.response?.data);
                console.log(err?.response?.status);
                console.log(err?.response?.headers);
                // DISPATCH ERROR
                dispatch({type:'PSW_UPDATE_ERROR', error: err?.response?.data.message});
            } else {
                console.log(err?.message);
                dispatch({type:'PSW_UPDATE_ERROR', error: err?.message});
            }          
        }finally{
            setTimeout(()=>{
                
                resetState();
            }, 3000);
        }
        
    }, [dispatch, resetState]);

    // Return
    return (
        <PswUpdateContext.Provider value={pswUpdateFn}>
            <PswUpdateLoading.Provider value={state.pswUpdateLoading}>
                <PswUpdateError.Provider value={state.pswUpdateError}>
                    <PswUpdateData.Provider value={state.pswUpdateData}>
        {children}
                    </PswUpdateData.Provider>
                </PswUpdateError.Provider>
            </PswUpdateLoading.Provider>
        </PswUpdateContext.Provider>
    )
    
};

export default PswUpdateContext;;