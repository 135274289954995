
const generatePswReducer = (state, action) =>{
    switch(action.type){

        case 'PSW_GENERATED' :
            return {
                ...state, generated: action.psw
            }
        case 'PSW_LENGTH' :
            return {
                ...state, pswLength: action.pswLength
            }
        case 'PSW_UPPER' :
            return {
                ...state, upper: action.upper
            }
        case 'PSW_NUM' :
            return {
                ...state, num: action.num
            }
        case 'PSW_SYM' :
            return {
                ...state, sym: action.sym
            }
    
        // DEFAULT
        default:
            return state;
    }
};

export default generatePswReducer;