import React from 'react';
import './FirstLogin.css';
import { useNavigate } from 'react-router-dom';
import FirstLoginContext, { FirstLoginLoadingContext, FirstLoginErrorContext, FirstLoginSuccessContext, FirstLoginMessageContext } from '../context/FirstLoginContextReducer/FirstLoginContext';
import { BsHourglassSplit } from 'react-icons/bs';
import sanitizeInput from '../hooks/sanitizeInput';
import { UserInContext } from '../context/LogoutContextReducer/LogoutContext';
import { toast } from 'react-toastify';


const FirstLogin = () => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    // HISTORY
    const history = useNavigate();

    // LOGIN CONTEXT 
    const firstLoginFn = React.useContext(FirstLoginContext);
    const firstLoginLoading = React.useContext(FirstLoginLoadingContext);
    const firstLoginError = React.useContext(FirstLoginErrorContext);
    const firstLoginSuccess = React.useContext(FirstLoginSuccessContext);
    const firstLoginMessage = React.useContext(FirstLoginMessageContext);

    // USER IN CONTEXT
    const usrIn = React.useContext(UserInContext);

    // CHECK IF USER IS LOGGED IN
    React.useEffect(() => {
        if (usrIn !== null) {
            history('/');
        }
    }, [usrIn, history]);

    // HANDLE SUBMIT
    const handleSubmit = React.useCallback(async (e) => {
        e.preventDefault();
        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(email) === false) { // eslint-disable-line
            toast.error('Invalid Email.');
            return;
        };

        try {
            await firstLoginFn(email, password);

        } catch (err) {
            toast.error(err?.message);
        }
    }, [firstLoginFn, email, password]);


    // REACT USE EFFECT
    React.useEffect(() => {
        if (firstLoginError !== '') {
            // console.log(loginError.message)
            toast.error(firstLoginError);
        }
    }, [firstLoginError]);

    // REACT USE EFFECT
    React.useEffect(() => {
        // console.log('success',firstLoginSuccess);
        // console.log('success',firstLoginMessage);

        if (firstLoginSuccess && firstLoginMessage === 'OK') {
            // console.log('success',firstLoginSuccess);
            // console.log('success',firstLoginMessage);

            toast(firstLoginMessage);
            
            setTimeout(() => {
                history(`/auth/login/${email}`);
            }, 1000);
            setEmail('');
            setPassword('');
        }

        if (!firstLoginSuccess && firstLoginMessage !== '') {
            // console.log('success',firstLoginSuccess);
            // console.log('success',firstLoginMessage);
            toast(firstLoginMessage);
        }

        // eslint-disable-next-line
    }, [firstLoginSuccess, firstLoginMessage]);

    // FORM REF
    const formRef = React.useRef(null);
    // console.log(formRef)

    // RETURN 
    return (
        <div className='FirstLogin'>
            <h1>First Login</h1>

            <form ref={formRef} autoComplete='off' onSubmit={handleSubmit}>

                <div className="firstLogin-loading">
                    {firstLoginLoading && <BsHourglassSplit />}
                </div>

                <div className="email">
                    {/* <label htmlFor="email">Email</label> */}
                    <input
                        type="email"
                        placeholder='Email'
                        autoFocus
                        title='Insert email'
                        id='email'
                        name='email'
                        value={email}
                        maxLength={50}
                        minLength={6}
                        onChange={e => setEmail(sanitizeInput(e.target.value))}
                    // required
                    />
                </div>


                <div className="password">
                {/* <label htmlFor="password">Password</label> */}
                    <input
                        type="password"
                        placeholder='Password'
                        title='Insert password'
                        id='password'
                        name='password'
                        value={password}
                        maxLength={50}
                        // minLength={6}
                        onChange={e => setPassword(sanitizeInput(e.target.value))}
                    // required
                    />
                </div>

                <button className='btnSubmit'>
                    login
                </button>

            </form>
            {/* <p className='info'>
                Do not have an account? <Link to='/auth/register'>Register</Link>
            </p> */}
        </div>
    )
}
export default FirstLogin;
