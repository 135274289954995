
const pswNewReducer = (state, action)=>{

    switch(action.type){
        case 'PSW_NEW_LOADING':
            return {
                ...state, pswNewLoading: action.loading
            }
        case 'PSW_NEW_ERROR':
            return {
                ...state, pswNewError: action.error
            }
        case 'PSW_NEW_DATA':
            return {
                ...state, pswNewData: action.data
            }
        // DISPATCH RESET
        case 'PSW_NEW_RESET':
            return {
                state: action.reset 
            }
        
        // DEFAULT
        default:
            return state;
    }
};

export default pswNewReducer;