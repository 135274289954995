import React from 'react';
import './UploadBck.css';
import api from '../config/api';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';

const UploadBck = () => {
  const [file, setFile] = React.useState(null);

  // GET COOKIE
  // const cookies = new Cookies();

  // HANDLE CHANGE
  const handleChange = e => {
    // console.log(e.target.files[0]);
    setFile(e.target.files[0]);
  };

  // HANDLE SUBMIT
  const handleSubmit = async e => {
    e.preventDefault();

    // GET COOKIE
    const cookies = new Cookies();

    // TKN
    let tkn = cookies.get('tkn');

    if (!tkn) return;

    const formData = new FormData();
    formData.append('file', file);

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${tkn}`,
      },
    }
    // console.log(formData);
    let res = await api.post(`/bck/upload`, formData, config);
    // console.log(res)

    if (res.data.success) {
      return toast.success(res.data.message);
    }

    toast.error(`Something wrong`);
  };


  // RETURN
  return (
    <form className='Uploadbck' encType="multipart/form-data" onSubmit={handleSubmit}>
      <input type="file" className='upload-input-file' name='file' id='file' onChange={handleChange} />
      <button className='upload-btn-submit' type="submit">Upload</button>
    </form>
  )
}

export default UploadBck;