
const pswGetSingleReducer = (state, action) =>{
    switch(action.type){
        case 'PSW_GET_SINGLE_LOADING':
            return {
                ...state, pswGetSingleLoading: action.loading
            };
        case 'PSW_GET_SINGLE_ERROR':
            return {
                ...state, pswGetSingleError: action.error
            };
        case 'PSW_GET_SINGLE_DATA':
            return {
                ...state, pswGetSingleData: action.data
            };
        // RESET
        case 'PSW_GET_SINGLE_RESET':
            return {
                state: action.payload
            };

        // DEFAULT
        default:
            return state;
    }
};

export default pswGetSingleReducer;