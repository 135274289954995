
const pswsGetAllReducer = (state, action)=>{
    switch(action.type){
        case 'PSWS_GET_ALL_LOADING':
            return {
                ...state, pswsGetAllLoading: action.loading
            }
        case 'PSWS_GET_ALL_ERROR':
            return {
                ...state, pswsGetAllError: action.error
            }
        case 'PSWS_GET_ALL_DATA':
            return {
                ...state, pswsGetAllData: action.data
            }
        // RESET
        case 'PSWS_GET_ALL_RESET':
            return {
                 ...action.reset 
            }

        // DEFAULT
        default:
            return state;
    };
};

export default pswsGetAllReducer;