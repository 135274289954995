import React from 'react';
import searchReducer from './SearchReducer';
import Cookies from 'universal-cookie';
import api from '../../config/api';

const SearchContext = React.createContext();
export const SearchLoading = React.createContext('');
export const SearchError = React.createContext('');
export const SearchData = React.createContext([]);
export const SearchKey = React.createContext('');
export const SearchSetKey = React.createContext();
export const SearchResetData = React.createContext();

export const SearchProvider = ({ children }) => {

    // INITIAL STATE
    const initialState = {
        searchLoading: false,
        searchError: '',
        search: '',
        searchData: [],
    };

    // REDUCER
    const [state, dispatch] = React.useReducer(searchReducer, initialState);
  

     // SEARCH

     // REGISTER
    const searchFn = React.useCallback(async(search)=>{

      try{
            // CHECK SEARCH KEY
            if(search === ''){
              // DISPATCH ERROR
             return dispatch({type: 'SEARCH_ERROR', error: 'Please enter a search key'});
            };

           // GET COOKIE
           const cookies = new Cookies();
           // TKN
           let tkn = cookies.get('tkn');
           
           if(!tkn) return;
          
           // DISPATCH LOADING
            dispatch({type:'SEARCH_LOADING', loading: true});
          
           // Confid
           let config ={
              headers:{
                  'Content-Type':'application/json',
                  Authorization:`Bearer ${tkn}`
              }
          }
          // FETCH POST
          const res = await api.get(`/api/psws/search?search=${search}`, config);
       
          if(res.status === 200){
              // DISPATCH SUCCESS
              dispatch({type:'SEARCH_DATA', data: res?.data?.arrSorted});
          };

      }catch(err){
          if (err.response) {
              // console.log(err.response);
              // console.log(err.response.data);
              // DISPATCH ERROR
              dispatch({type:'SEARCH_ERROR', error: err?.response?.data?.message});
              // console.log(err.response.status);
              // console.log(err.response.headers);
          } else {
              console.log(err.message);
              // DISPATCH ERROR
              dispatch({type:'SEARCH_ERROR', error: err?.message});
          }
      }finally{
          setTimeout(()=>{
              // DISPATCH LOADING
              dispatch({type:'SEARCH_LOADING', loading: false});
              // DISPATCH ERROR
              dispatch({type:'SEARCH_ERROR', error: ''});
          }, 3000);
      }
  }, [dispatch]);

    // DISPATCH SET SEARCH KEY
    const setSearch = React.useCallback(
        (search) => {
            dispatch({type: 'SEARCH_SET', search: search});
        }, [dispatch]);


      // DISPATCH RESET SEARCH DATA

      const resetSearchData =  React.useCallback(() => {
        dispatch({type: 'RESET_SEARCH_DATA', searchData: []});
        setSearch('');
    },[dispatch, setSearch]);

    // RETURN
    return(
        <SearchContext.Provider value={searchFn}>
              <SearchKey.Provider value={state.search}>
                 <SearchSetKey.Provider value={setSearch}>
                    <SearchData.Provider value={state.searchData}>
                       <SearchLoading.Provider value={state.searchLoading}>
                        <SearchError.Provider value={state.searchError}>
                         <SearchResetData.Provider value={resetSearchData}>
                            {children}
                           </SearchResetData.Provider>
                         </SearchError.Provider>
                       </SearchLoading.Provider>
                     </SearchData.Provider> 
                   </SearchSetKey.Provider>
                 </SearchKey.Provider>
        </SearchContext.Provider>
    )

};

export default SearchContext;