import React from 'react';
import api from '../../config/api';
import registerReducer from './RegisterReducer';


const RegisterContext = React.createContext();
export const RegisterLoadingContext = React.createContext(false);
export const RegisterErrorContext = React.createContext('');
export const RegisterSuccessContext = React.createContext('');
export const RegisterUserContext = React.createContext({});
export const RegisterUserResetContext = React.createContext({});

export const RegisterProvider = ({children})=>{

    // INITIAL STATE
    const initialState = {
        registerLoading: false,
        registerError: '',
        registerSuccess: '',
        registerUser:{username:'', email:'', secret:'', otpauthurl:''}
    };

    // REDUCER
    const [state, dispatch] = React.useReducer(registerReducer, initialState);


    // REGISTER
    const registerFn = React.useCallback(async(username, email, password)=>{

        try{
            // DISPATCH LOADING
            dispatch({type: 'REGISTER_LOADING', loading: true});
            
             // Confid
             let config ={
                headers:{
                    'Content-Type':'application/json',
                    // Authorization:`Bearer ${tkn}`
                }
            }
            // FETCH POST
            const res = await api.post('/auth/sign', {
                username,
                email,
                password,
            },config);
            
            if(res.status === 200){
                // DISPATCH SUCCESS
                // console.log(res.data);
                dispatch({type: 'REGISTER_SUCCESS', success: res?.data?.message, user:res?.data?.user});
            };

        }catch(err){
            if (err.response) {
                // console.log(err.response);
                // console.log(err.response.data);
                // DISPATCH ERROR
                dispatch({type: 'REGISTER_ERROR', error: err?.response?.data});
                // console.log(err.response.status);
                // console.log(err.response.headers);
            } else {
                console.log(err.message);
                // DISPATCH ERROR
                dispatch({type: 'REGISTER_ERROR', error: err.message});
            }
        }finally{
            setTimeout(()=>{
                // DISPATCH LOADING
                dispatch({type: 'REGISTER_LOADING', loading: false});
                // DISPATCH ERROR
                dispatch({type: 'REGISTER_ERROR', error: ''});
            }, 3000);
        }
    }, [dispatch]);


    // REGISTER RESET
    const registerUserResetFn = ()=>{
        dispatch({type:'REGISTER_RESET', user:{username:'', email:'', secret:'', otpauthurl:''}})
    }
    

    // RETURN
    return (
        <RegisterContext.Provider value={registerFn}>
            <RegisterLoadingContext.Provider value={state.registerLoading}>
                <RegisterErrorContext.Provider value={state.registerError}>
                    <RegisterSuccessContext.Provider value={state.registerSuccess}>
                        <RegisterUserContext.Provider value={state.registerUser}>
                            <RegisterUserResetContext.Provider value={registerUserResetFn}>
                                    {children}
                            </RegisterUserResetContext.Provider>
                        </RegisterUserContext.Provider>
                    </RegisterSuccessContext.Provider>
                </RegisterErrorContext.Provider>
            </RegisterLoadingContext.Provider>
        </RegisterContext.Provider>    
    )
   
}

export default RegisterContext;