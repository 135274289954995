
const loginReducer = (state, action) =>{
    switch(action.type){
        case 'LOGIN_LOADING':
            return {
                ...state, loginLoading: action.loading
            }
        case 'LOGIN_ERROR':
            return {
                ...state, loginError: action.error
            }
        case 'LOGIN_SUCCESS':
            return {
                ...state, loginSuccess: action.loginSuccess 
            }

        // DEFAULT
        default:
            return state;
    }
};

export default loginReducer;