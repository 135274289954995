import React from 'react';
import './Random.css';
import { useNavigate } from 'react-router-dom';
import { UserInContext } from '../context/LogoutContextReducer/LogoutContext';
import GenerateContext, { GenerateLengthContext, GenerateSetLengthContext, GenerateUpperContext, GenerateSetUpperContext, GenerateNumContext, GenerateSetNumContext, GenerateSymContext, GenerateSetSymContext, GenerateDataContext } from '../context/GeneratePswContextReducer/GeneratePswContext';

const Random = () => {

    // CONTEXT
    const usrIn = React.useContext(UserInContext);
    // GENERATE CONTEXT
    const pswLength = React.useContext(GenerateLengthContext);
    const setPswLength = React.useContext(GenerateSetLengthContext);
    const upper = React.useContext(GenerateUpperContext);
    const setUpper = React.useContext(GenerateSetUpperContext);
    const num = React.useContext(GenerateNumContext);
    const setNum = React.useContext(GenerateSetNumContext);
    const sym = React.useContext(GenerateSymContext);
    const setSym = React.useContext(GenerateSetSymContext);
    const genPswFn = React.useContext(GenerateContext);
    const generated = React.useContext(GenerateDataContext);

    // HISTORY
    const history = useNavigate();


    // UseEffect
    React.useEffect(() => {

        if (usrIn === null) {
            history('/');
        }

    }, [usrIn, history]);

    // CHECK MAX LENGTH
    React.useEffect(() => {
        if (pswLength > 33) {
            setPswLength(33);
        } else if (pswLength < 8) {
            setPswLength(8);
        }
    }, [pswLength, setPswLength]);

    // RETURN 
    return (
        <>
            <div className='Random'>
                <div className="container">

                    <h1>Password Generator</h1>
                    <div className="result">
                        <input
                            type="text"
                            value={generated}
                            readOnly
                        />
                    </div>

                    <div className="settings">
                        <div className="set">
                            <label htmlFor="length">Length</label>
                            <input type="number" id='length' name='length'
                                min='8' max='33'
                                value={pswLength}
                                onChange={e => setPswLength(e.target.value)}
                            />
                        </div>
                        <div className="set">
                            <label htmlFor="upper">Upper Case</label>
                            <input type="checkbox" id='upper' name='upper'
                                value={upper}
                                checked={upper}
                                onChange={e => setUpper(e.target.checked)}
                            />
                        </div>
                        <div className="set">
                            <label htmlFor="num">Include Num</label>
                            <input type="checkbox" id='num' name='num'
                                value={num}
                                checked={num}
                                onChange={e => setNum(e.target.checked)}
                            />
                        </div>
                        <div className="set">
                            <label htmlFor="sym">Include Symbols</label>
                            <input type="checkbox" id='sym' name='sym'
                                value={sym}
                                checked={sym}
                                onChange={e => setSym(e.target.checked)}
                            />
                        </div>
                        <div className="rando-btn-wrapper">
                            <button
                                onClick={genPswFn} className='generate'
                                disabled={pswLength > 33 || pswLength < 8 ? true : false}
                            >
                                Generate
                            </button>

                        </div>
                    </div>
                </div>

            </div>

        </>)
}

export default Random
