import React from 'react';
import api from '../../config/api.js';
import Cookies from 'universal-cookie';
import pswGetSingleReducer from './PswGetSingleReducer.js';

const PswGetSingleContext = React.createContext();
export const PswGetSingleLoading = React.createContext(false);
export const PswGetSingleError = React.createContext('');
export const PswGetSingleData = React.createContext({});
export const PswGetSingleReset = React.createContext({});

export const PswGetSingleProvider = ({children}) =>{

    // Initial state
    const initialState =  React.useMemo(() => {
            return {
            pswGetSingleLoading: false,
            pswGetSingleError: '',
            pswGetSingleData: {
                site: '',
                user: '',
                psw: ''
            }
        }
    },[]);
    // REDUCER
    const [state, dispatch] = React.useReducer(pswGetSingleReducer, initialState);


    // Fetch single psw
    const pswFetchSingleFn = React.useCallback(async (id) =>{
        try{
            // GET COOKIE
            const cookies = new Cookies();

            // TOKEN
            let tkn = cookies.get('tkn');
            // console.log(tkn);

            if(!id) return;

            if(!tkn) return;
            
            // DISPATCH LOADING
            dispatch({type: 'PSW_GET_SINGLE_LOADING', loading: true});
     
            // Config
            let config = {
                headers:{
                    'Content-Type':'application/json',
                    Authorization: `Bearer ${tkn}`
                }
            };
            
            // Set Url
            let url = `/api/psws/single/${id}`;
            // Fetch
            const res = await api.get(url, config);
            if(res.status === 200){
                // console.log(res.data);
                // DISPATCH DATA
                dispatch({type: 'PSW_GET_SINGLE_DATA', data: res.data});
            };

        }catch(err){
            console.log(err?.message);
            if(err?.response){
                // DISPATCH ERROR
                dispatch({type: 'PSW_GET_SINGLE_ERROR', error: err?.response?.data?.message});
            }else{
                // DISPATCH ERROR
                dispatch({type: 'PSW_GET_SINGLE_ERROR', error: err?.message});
            }
        }finally{
            setTimeout(()=>{
                // DISPATCH LOADING
                dispatch({type: 'PSW_GET_SINGLE_LOADING', loading: false});
                dispatch({type: 'PSW_GET_SINGLE_ERROR', error: ''});
            }, 3000);
        };
    }, [dispatch]);

    // RESET STATE
    const pswGetSingleResetFn = React.useCallback(() =>{
        dispatch({type: 'PSW_GET_SINGLE_RESET', payload: initialState});
    },[dispatch, initialState]);


    // Return
    return (
        <PswGetSingleContext.Provider value={pswFetchSingleFn}>
            <PswGetSingleLoading.Provider value={state.pswGetSingleLoading}>
                <PswGetSingleError.Provider value={state.pswGetSingleError}>
                    <PswGetSingleData.Provider value={state.pswGetSingleData}>
                        <PswGetSingleReset.Provider value={pswGetSingleResetFn}>
                            {children}
                        </PswGetSingleReset.Provider>
                    </PswGetSingleData.Provider>
                </PswGetSingleError.Provider>
            </PswGetSingleLoading.Provider>
        </PswGetSingleContext.Provider>
    )

};

export default PswGetSingleContext;