import React from 'react';
import api from '../../config/api.js';
import Cookies from 'universal-cookie';
import pswGetPswReducer from './PswGetPswReducer.js';


const PswGetPswContext = React.createContext();
export const PswGetPswLoading = React.createContext();
export const PswGetPswError = React.createContext();
export const PswGetPswData = React.createContext();

export const PswGetPswProvider = ({children}) =>{

    // INITIAL STATE
    const initialState = {
        pswGetPswLoading: false,
        pswGetPswError: '',
        pswGetPswData: ''
    };

    // REDUCER
    const [state, dispatch] = React.useReducer(pswGetPswReducer, initialState);

    
    // POST SINGEL PSW
    const pswFetch = React.useCallback(async id => {
        try {

             // GET COOKIE
             const cookies = new Cookies();

            // DISPATCH LOADING
            dispatch({type:'PSW_GET_PSW_LOADING', loading:true});
            
            // TKN
            let tkn = cookies.get('tkn');

            if(!tkn) return;

            if(!id) return;

            let config = {
                headers:{
                    'Content-Type':'application/json',
                    Authorization: `Bearer ${tkn}`
                }
            }
            const res = await api.get(`/api/psws/${id}`, config);
            //   console.log(res.data);
            // DISPATCH DATA
            dispatch({type:'PSW_GET_PSW_DATA', data:res.data});
             
        } catch (err) {
            if (err?.response) {
                console.log(err?.response?.data);
                console.log(err?.response?.status);
                console.log(err?.response?.headers);
                // DISPATCH ERROR
                dispatch({type:'PSW_GET_PSW_ERROR', error:err?.response?.status});
            } else {
                console.log(err?.message);
                // DISPATCH ERROR
                dispatch({type:'PSW_GET_PSW_ERROR', error:err?.message});
            };
        } finally {
            setTimeout(()=>{
                // DISPATCH LOADING
                dispatch({type:'PSW_GET_PSW_LOADING', loading:false});
                dispatch({type:'PSW_GET_PSW_ERROR', error:''});
                dispatch({type:'PSW_GET_PSW_DATA', data:''});
           }, 1500);
        }
    }, [dispatch]);


    return (
        <PswGetPswContext.Provider value={pswFetch}>
            <PswGetPswLoading.Provider value={state.pswGetPswLoading}>
                <PswGetPswError.Provider value={state.pswGetPswError}>
                <PswGetPswData.Provider value={state.pswGetPswData}>
                  {children}
                </PswGetPswData.Provider>
                </PswGetPswError.Provider>
            </PswGetPswLoading.Provider>
        </PswGetPswContext.Provider>
    )

}

export default PswGetPswContext;