import './NewPsw.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {UserInContext} from '../context/LogoutContextReducer/LogoutContext';
import PswsGetPageContext from '../context/PswsGetPageContextReducer/PswsGetPageContext';
import PswNewContext, {PswNewData, PswNewError, PswNewLoading, PswNewReset} from '../context/PswNewContextReducer/PswNewContext';
import GenerateContext, {GenerateDataContext} from '../context/GeneratePswContextReducer/GeneratePswContext';
import { BsHourglassSplit } from 'react-icons/bs';
import sanitizeInput from '../hooks/sanitizeInput';
import { toast } from 'react-toastify';


const NewPsw = () => {

    // CONTEXT
    const usrIn = React.useContext(UserInContext);
    // GENERATE CONTEXT
    const genPswFn = React.useContext(GenerateContext);
    const generated = React.useContext(GenerateDataContext);
    // console.log(generated)

    // History
    const history = useNavigate();

    // USE EFFECT CHECK USER IS IN 
    React.useEffect(()=>{
        if(!usrIn){
            history('/');
        }
    },[usrIn, history]);

    // Values
    const [values, setValues] = React.useState({
        site: '',
        user: '',
        psw: `${generated}`,
        usr_id: usrIn ? usrIn.id : null
    });

    // CONTEXT PSWS GET PAGE
    const fetchPsws = React.useContext(PswsGetPageContext);

    // CONTEXT PSW NEW
    const pswNewFn = React.useContext(PswNewContext);
    const pswNewData = React.useContext(PswNewData);
    const pswNewError = React.useContext(PswNewError);
    const pswNewLoading = React.useContext(PswNewLoading);
    const pswNewResetFn = React.useContext(PswNewReset);

    // Handle Change
    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: sanitizeInput(value) });
    }
    // HANDLE SUBMIT
    const handleSubmit = async e => {
        e.preventDefault();
        if (values.site === '' || values.user === '' || values.psw === '') {
            toast.error('Add values.')
            return
        }
        // POST NEW PSW
        await pswNewFn(values);
    };

    // USE EFFECT NEW PASSWORD
    React.useEffect(()=>{
        if(pswNewData){
            // console.log(pswNewData)
            // ALERT
            toast.success(pswNewData?.message);
            // UPDATE STATE
            (async () => await fetchPsws())();
            // RESET NEW PSW STATE
            pswNewResetFn();
            // REDIRECT TO HOME
            history('/');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pswNewData, history]);

    // USE EFFECT NEW PASSWORD ERROR
    React.useEffect(()=>{
        if(pswNewError){
            toast.error(pswNewError);
        }
    },[pswNewError]);


    // CHANGE PASSWORD
    React.useEffect(()=>{

        setValues({ ...values, psw: generated });
        
        // eslint-disable-next-line
    }, [generated])

    // RETURN
    return (
        <main className='Newpsw'>
            <div className="pswWrap">
                <h1 className='newPswTitle'>New Psw</h1>
                
                <div className="new-psw-loading">
                {pswNewLoading && <BsHourglassSplit />}
                </div>

                <form autoComplete='off'
                    onSubmit={handleSubmit}>
                    <div className="pswSite">
                        <label htmlFor="site">Site</label>
                        <input
                            type="text"
                            id='site'
                            name='site'
                            value={values.site}
                            onChange={e => handleChange(e)}
                            autoFocus
                        />
                    </div>
                    <div className="pswUser">
                        <label htmlFor="user">User</label>
                        <input
                            id='user'
                            name='user'
                            value={values.user}
                            onChange={e => handleChange(e)}
                            type="text"
                        />
                    </div>
                    <div className="pswPsw">
                       
                        <div className="generate">
                        <label htmlFor="psw">Password</label>
                        <span className="btnGenerate">
                           <small> Generate </small>
                            <img onClick={()=> genPswFn()} src="/imgs/generate.svg" alt="generate" />
                            
                        </span>
                        </div>

                        <input
                            id='psw'
                            name='psw'
                            value={values.psw}
                            onChange={e => handleChange(e)}
                            type="text"
                        />
                    </div>
                    <button className='btnSubmit'>
                        SUBMIT
                    </button>

                </form>
            </div>
        </main>
    )
}

export default NewPsw;
