import React from 'react';
import './Login.css';
import { useParams, useNavigate } from "react-router-dom";
import LoginContext, { LoginLoadingContext, LoginErrorContext, LoginSuccessContext } from '../context/LoginContextReducer/LoginContext';
import { FirstLoginSuccessContext, FirstLoginSuccessResetContext } from '../context/FirstLoginContextReducer/FirstLoginContext';
import { BsHourglassSplit } from 'react-icons/bs';
// import sanitizeInput from '../hooks/sanitizeInput';
import { UserInContext } from '../context/LogoutContextReducer/LogoutContext';
import toast from 'react-hot-toast';
import OtpWrapper from './OtpWrapper';


const Login = () => {
    const [otp, setOtp] = React.useState('');
    const { email } = useParams();
    const [userEmail, setUserEmail] = React.useState(email|| '');
    const [isSuccess, setIsSuccess] = React.useState(false);
    //  console.log(userEmail);

    // HISTORY
    const history = useNavigate();

    // LOGIN CONTEXT 
    const loginFn = React.useContext(LoginContext);
    const loginLoading = React.useContext(LoginLoadingContext);
    const loginError = React.useContext(LoginErrorContext);
    const loginSuccess = React.useContext(LoginSuccessContext);

    // FIRST LOGIN CONTEXT
    const firstLoginSuccess = React.useContext(FirstLoginSuccessContext);
    const firstLoginSuccessResetFn = React.useContext(FirstLoginSuccessResetContext)
    
    // console.log(firstLoginSuccess);
    // console.log(loginSuccess);

    // USER IN CONTEXT
    const usrIn = React.useContext(UserInContext);

    // CHECK IF USER IS LOGGED IN
    React.useEffect(() => {
       
        if (!firstLoginSuccess) {
            history('/');
        }
    }, [firstLoginSuccess, history]);

    // HANDLE SUBMIT
    const handleSubmit = React.useCallback(async () => {

        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(userEmail) === false) { // eslint-disable-line
            toast.error('Invalid Email.');
            return;
        };

        try {
            await loginFn(userEmail, otp);

        } catch (err) {
            toast.error(err?.message);
        }
    }, [loginFn, userEmail, otp]);

    // SUBMIT
    React.useEffect(() => {
        if (otp.length === 6) {
            handleSubmit()
        }
    }, [otp, handleSubmit]);

    // REACT USE EFFECT
    React.useEffect(() => {
        if (loginError !== '') {
            // console.log(loginError.message)
            toast.error(loginError);
        }
    }, [loginError]);


    // REACT USE EFFECT
    React.useEffect(() => {
        if (usrIn && loginSuccess === 'LOGIN OK' && !isSuccess) {
            setOtp('');
            setUserEmail('');
            setIsSuccess(true);
        }
        // eslint-disable-next-line
    }, [usrIn, loginSuccess]);

       // REACT USE EFFECT
       React.useEffect(() => {
        if (isSuccess) {
            toast.success('Login OK');
            firstLoginSuccessResetFn();
            history('/');
        }
        // eslint-disable-next-line
    }, [isSuccess]);


    // FORM REF
    const formRef = React.useRef(null);
    // console.log(formRef)

    // RETURN 
    return (
        <div className='Login'>
            <h2>{userEmail} login</h2>

            <form ref={formRef} autoComplete='off' onSubmit={e => e.preventDefault()}>

                <div className="login-loading">
                    {loginLoading && <BsHourglassSplit />}
                </div>

                {/* <div className="email">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        placeholder='Email'
                        autoFocus
                        title='Insert email'
                        id='email'
                        name='email'
                        value={email}
                        maxLength={50}
                        minLength={6}
                        onChange={e => setEmail(sanitizeInput(e.target.value))}
                    // required
                    />
                </div> */}


                <div className="password">
                    <OtpWrapper setOtp={setOtp} />
                </div>

                {/* <button className='btnSubmit'>
                    login
                </button> */}

            </form>
            {/* <p className='info'>
                Do not have an account? <Link to='/auth/register'>Register</Link>
            </p> */}
        </div>
    )
}

export default Login;
