
import React from 'react';
import { useNavigate } from "react-router-dom";
import './About.css'
import { Tranquiluxe } from "uvcanvas"
import { UserInContext } from '../context/LogoutContextReducer/LogoutContext';


const About = () => {

    const usrIn = React.useContext(UserInContext);
    // HISTORY
    const history = useNavigate();

    React.useEffect(()=> {
        if(!usrIn){
            history('/');
        }
    },[usrIn,history]);

    // RETURN
    return (
        <div className='About'>
            <div className="header">

            <h1>About</h1>
            <p>We are creators</p>
            <div className="imgWrap">
                <img src="/imgs/about-2.svg" alt="about" />
            </div>
            </div>
            <Tranquiluxe />
        </div>
    )
}

export default About
