import './Header.css';
import DarkContext, { DarkSetContext } from '../context/DarkContextReducer/DarkContext';
import React from 'react';
import { UserInContext } from '../context/LogoutContextReducer/LogoutContext';
import LogoutContext from '../context/LogoutContextReducer/LogoutContext';
import Cookies from 'universal-cookie';
import {jwtDecode as jwt_decode} from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const Header = ({ title }) => {
    const [expiration, setExpiration] = React.useState(0);

    // NAVIGATE
    const navigate = useNavigate();

    // DARK CONTEXT
    const isDark = React.useContext(DarkContext);
    const setDarkFn = React.useContext(DarkSetContext);
    // AUTH
    const usrIn = React.useContext(UserInContext);
    // console.log(usrIn)

    // LOGOUT CONTEXT
    const logoutFn = React.useContext(LogoutContext);

    // Import audio file
    let lightSwitch = new Audio('/sounds/light-switch.mp3');

    // HANDLE DARK MODE
    const handleDark = () => {
        // Add dark attribute
        if (!isDark) {
            lightSwitch.currentTime = 0;
            lightSwitch.play();
            setDarkFn('SET_DARK');
        } else {
            lightSwitch.currentTime = 0;
            lightSwitch.play();
            setDarkFn('SET_LIGHT');
        }
    }

    // CHECK TKN EXPIRATION STATE
    React.useEffect(() => {

        // COOKIE HOOK
        const cookies = new Cookies();
        let tkn = cookies.get('tkn');
        if (tkn) {
            let dec = jwt_decode(tkn);
            setExpiration((dec?.exp * 1000) - 60000);
        }

    }, [usrIn]);

    React.useEffect(() => {
        let interval;

        interval = setInterval(() => {
            const cookies = new Cookies();
            let tkn = cookies.get('tkn');
            if (tkn && expiration) {
                if (Date.now() >= (new Date(expiration).getTime())) {
                    // console.log('Expired');
                    setExpiration(0);
                    logoutFn();
                    console.log('expired');
                    clearInterval(interval);
                    if(window){
                        window.location.replace('/'); 
                    }

                } else {
                    // console.log('valid');
                }

            }
        }, 1000);

        return () => clearInterval(interval);

    }, [expiration, logoutFn]);


    // HANDLE DOUBLE CLICK
    const handleDoubleClick = () => {
        navigate('/auth/firstlogin');
    };

    // GO TO PROFILE
    const gotoProfile = () => {
        navigate('/profile');
    };

    // RETURN
    return (
        <header className='Header'>
            <div className="logo">
                <h1>{title}</h1>
            </div>
            <div className='header-username-wrapper'>
                {

                    usrIn && (
                        <>
                            <small onClick={gotoProfile} className='header-username'>{usrIn.username}</small>
                            <small className='header-expiration'> <strong>Exp: </strong> {new Date(expiration).toLocaleString()}</small>
                        </>
                    )
                }
            </div>
            <div className="darkMode" onClick={handleDark} onDoubleClick={handleDoubleClick}>
                {
                    isDark ? <img src="/imgs/sun.svg" alt="sun" />
                        :
                        <img src="/imgs/moon.svg" alt="moon" />
                }
            </div>

        </header>
    )
}

export default Header
