
const pswGetPswReducer = (state,action) =>{
    switch(action.type){
        case 'PSW_GET_PSW_LOADING':
            return {
                ...state, pswGetPswLoading: action.loading
            }
        case 'PSW_GET_PSW_ERROR':
            return {
                ...state, pswGetPswError: action.error
            }
        case 'PSW_GET_PSW_DATA':
            return {
                ...state, pswGetPswData: action.data
            }

        // DEFAULT
        default:
            return state;
    }
};

export default pswGetPswReducer;