import React from 'react';
import api from '../../config/api.js';
import Cookies from 'universal-cookie';
import pswDeleteReducer from './PswDeleteReducer.js';

const PswDeleteContext = React.createContext({});
export const PswDeleteLoading = React.createContext(false);
export const PswDeleteError = React.createContext('');
export const PswDeleteSuccess = React.createContext('');


export const PswDeleteProvider = ({ children }) => {


    // INITIAL STATE
    const initialState = {
        pswDeleteLoading: false,
        pswDeleteError: '',
        pswDeleteSuccess: '',
    };

    // REDUCER
    const [state, dispatch] = React.useReducer(pswDeleteReducer, initialState);


        // PSW DELETE
        const pswDeleteFn = React.useCallback(async id =>{
            try{
                // GET COOKIE
                const cookies = new Cookies();

                // DISPATCH LOADING
                dispatch({type:'PSW_DELETE_LOADING', loading: true});
                 // TKN
                 let tkn = cookies.get('tkn');

                 if(!tkn){
                    // DISPATCH ERROR
                    dispatch({type:'PSW_DELETE_ERROR', error: 'Not authorized.'});
                    return ;
                };
    
                let config = {
                    headers:{
                        'Content-Type':'application/json',
                        Authorization: `Bearer ${tkn}`
                    }
                }
                const res = await api.delete(`/api/psws/del/${id}`, config);
                // console.log(res);
                // console.log(res.data.message);
                // DISPATCH SUCCESS
                dispatch({type:'PSW_DELETE_SUCCESS', success: res.data.message});
    
            }catch(err)
            {
                if (err?.response) {
                    console.log(err?.response?.data);
                    console.log(err?.response?.status);
                    console.log(err?.response?.headers);
                    // DISPATCH ERROR
                    dispatch({type:'PSW_DELETE_ERROR', error: err?.response?.data});
                } else {
                    console.log(err.message);
                    // DISPATCH ERROR
                    dispatch({type:'PSW_DELETE_ERROR', error: err.message});
                }    
            }finally{
                setTimeout(()=>{
                    dispatch({type: 'PSW_DELETE_LOADING', loading: false});
                    dispatch({type:'PSW_DELETE_ERROR', error: ''});
                    dispatch({type: 'PSW_DELETE_SUCCESS', success: ''});
                },3000);
            }
        },[dispatch]);
    

    // RETURN
    return (
        <PswDeleteContext.Provider value={pswDeleteFn}>
            <PswDeleteLoading.Provider value={state.pswDeleteLoading}>
                <PswDeleteError.Provider value={state.pswDeleteError}>
                    <PswDeleteSuccess.Provider value={state.pswDeleteSuccess}>
                            {children}
                    </PswDeleteSuccess.Provider>
                </PswDeleteError.Provider>
            </PswDeleteLoading.Provider>
        </PswDeleteContext.Provider>
    );
};

export default PswDeleteContext;