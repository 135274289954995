import './Missing.css';
import { Link } from 'react-router-dom';

const Missing = () => {
    return (
        <main className='Missing'>
            <h1>Missing 404</h1>
            <Link to='/'>Go back to the homepage</Link>
        </main>
    )
}

export default Missing
