
const pswsGetPageReducer = (state, action)=>{
    switch(action.type){

        case 'PSWS_GET_PAGE_LOADING':
            return {
                ...state, pswsGetPageLoading: action.loading,
            };
        case 'PSWS_GET_PAGE_DATA':
            return {
                ...state, pswsGetPageData: action.data,
            };
        case 'PSWS_GET_PAGE_TOTAL':
            return {
                ...state, total: action.total,
            };
        case 'PSWS_GET_PAGE_PER_PAGE':
            return {
                ...state, perPage: action.perPage,
            };
        case 'PSWS_GET_PAGE_LAST_PAGE':
            return {
                ...state, lastPage: action.lastPage,
            };
        case 'PSWS_GET_PAGE_ERROR':
            return {
                ...state, pswsGetPageError: action.error,
            };
        case 'PSWS_GET_PAGE_SET_PAGE':
            return {
                ...state, page: action.page,
            };
        
        case 'PSWS_GET_PAGE_RESET':
            return {
                ...action.payload
            };

    
        // DEFAULT
        default:
            return state;
    };
}
export default pswsGetPageReducer;