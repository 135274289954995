
const logoutReducer = (state, action)=>{
    switch(action.type){
        case 'LOGOUT_LOADING':
            return {
                ...state, logoutReducer : action.loading
            }

        case 'LOGOUT_SUCCESS':
            return {
                ...state, logoutSuccess: action.success
            }
       
        case 'LOGOUT_ERROR':
            return {
                ...state, logoutError: action.error
            }
        case 'USRIN_STATE':
            return {
                ...state, usrIn: action.usrIn
            }

        // DEFAULT
        default:
            return state;

    }
}

export default logoutReducer;