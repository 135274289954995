
import './Nav.css';
import { Link, useLocation,useNavigate} from 'react-router-dom';
import React from 'react';
import LogoutContext, {UserInContext} from '../context/LogoutContextReducer/LogoutContext';
import SearchContext, {SearchKey, SearchSetKey, SearchLoading, SearchError} from '../context/SearchContextReducer/SearchContext';
import PswsGetPageContext from '../context/PswsGetPageContextReducer/PswsGetPageContext';
import Cookies  from 'universal-cookie';
import sanitizeInput from '../hooks/sanitizeInput';
import {toast} from 'react-toastify';


const Nav = () => {

    // LOCATION
    const location = useLocation();

    // NAVIGATE
    const navigate = useNavigate();

    // CONTEXT SEARCH
    const searchFn = React.useContext(SearchContext);
    const search = React.useContext(SearchKey);
    const setSearch = React.useContext(SearchSetKey);
    const searchLoading = React.useContext(SearchLoading);
    const searchError = React.useContext(SearchError);
    // console.log(search);

     // CONTEXT PSWS GET PAGE 
     const fetchPsws = React.useContext(PswsGetPageContext);

    // USER IN 
    const usrIn = React.useContext(UserInContext);
    // LOGOUT CONTEXT
    const logoutFn = React.useContext(LogoutContext);
   
    // HANDLE SEARCH
    const handleSearch = e => {
        setSearch(sanitizeInput(e.target.value));
    };

    // COOKIE HOOK
    const cookies = new Cookies();

    // HANDLE LOGOUT
    const handleLogout = async ()=>{
        // console.log('out');
        try{
        cookies.remove('tkn', { path: '/' });
        await logoutFn();
        }catch(err){
         toast.error(err);
        }
    };

     // LOGIN 
     React.useEffect(()=>{
       
        if(usrIn === null){
            setSearch('');
        };
       
    }, [usrIn, setSearch]);

    // SEARCH ERROR
    React.useEffect(()=>{
        if(searchError !== ''){
            toast.error(searchError);
        }
    }, [searchError]);

    // HANDLE SEARCH SUBMIT
    const handleSearchSubmit = async e => {
        e.preventDefault();
        
        await searchFn(search);
        
        // Redirect to search page
        if(location.pathname !== '/search'){
            navigate('/search');
        }
        // RESET INPUT FIELD
        setSearch('');
    };


    // RETURN 
    return (
        <nav className='Nav'>
            <div className="search">
                {
                    (usrIn !== null) &&
                    <form className='searchForm' onSubmit={handleSearchSubmit}>
                        <label htmlFor="search">Search Post</label>
                        <input
                            id='search'
                            name='search'
                            placeholder='Search'
                            type="text"
                            value={search}
                            onChange={e => handleSearch(e)}
                            required
                        />
                        {!searchLoading &&
                        <button className='btn-search-submit' type='submit'>
                           <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <circle cx="10" cy="10" r="7" />
                            <line x1="21" y1="21" x2="15" y2="15" />
                            </svg> 
                        </button>
                        }

                        <div className="isSearching">
                            {searchLoading ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <circle cx="10" cy="10" r="7" />
                            <line x1="21" y1="21" x2="15" y2="15" />
                            </svg> : null}
                        </div>
                    </form>

                }

            </div>

            <div className="links">
                <ul>

                    {
                        usrIn && 
                        <>
                         <li>
                        <Link onClick={ async () => await fetchPsws()} className='home-btn' to="/">
                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32" width="512px" height="512px"><path d="M 16 2.59375 L 15.28125 3.28125 L 2.28125 16.28125 L 3.71875 17.71875 L 5 16.4375 L 5 28 L 14 28 L 14 18 L 18 18 L 18 28 L 27 28 L 27 16.4375 L 28.28125 17.71875 L 29.71875 16.28125 L 16.71875 3.28125 Z M 16 5.4375 L 25 14.4375 L 25 26 L 20 26 L 20 16 L 12 16 L 12 26 L 7 26 L 7 14.4375 Z"/>
                            </svg>
                        </Link>
                        </li>
                        <li>
                            <Link className='about-btn' to="/about">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-circle" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <circle cx="12" cy="12" r="9" />
                                <line x1="12" y1="8" x2="12.01" y2="8" />
                                <polyline points="11 12 12 12 12 16 13 16" />
                                </svg>
                            </Link>
                        </li>
                         <li>
                         <Link className='plus-btn' to="/newpsw">
                         <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 128 128" width="512px" height="512px">    <path stroke="currentColor" d="M 64 6.0507812 C 49.15 6.0507812 34.3 11.7 23 23 C 0.4 45.6 0.4 82.4 23 105 C 34.3 116.3 49.2 122 64 122 C 78.8 122 93.7 116.3 105 105 C 127.6 82.4 127.6 45.6 105 23 C 93.7 11.7 78.85 6.0507812 64 6.0507812 z M 64 12 C 77.3 12 90.600781 17.099219 100.80078 27.199219 C 121.00078 47.499219 121.00078 80.500781 100.80078 100.80078 C 80.500781 121.10078 47.500781 121.10078 27.300781 100.80078 C 7.0007813 80.500781 6.9992188 47.499219 27.199219 27.199219 C 37.399219 17.099219 50.7 12 64 12 z M 64 42 C 62.3 42 61 43.3 61 45 L 61 61 L 45 61 C 43.3 61 42 62.3 42 64 C 42 65.7 43.3 67 45 67 L 61 67 L 61 83 C 61 84.7 62.3 86 64 86 C 65.7 86 67 84.7 67 83 L 67 67 L 83 67 C 84.7 67 86 65.7 86 64 C 86 62.3 84.7 61 83 61 L 67 61 L 67 45 C 67 43.3 65.7 42 64 42 z"/>
                         </svg>
                         </Link>
                        </li>
                        <li>
                        <Link className='random-btn' to='/random'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-grain" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <circle cx="4.5" cy="9.5" r="1" />
                        <circle cx="9.5" cy="4.5" r="1" />
                        <circle cx="9.5" cy="14.5" r="1" />
                        <circle cx="4.5" cy="19.5" r="1" />
                        <circle cx="14.5" cy="9.5" r="1" />
                        <circle cx="19.5" cy="4.5" r="1" />
                        <circle cx="14.5" cy="19.5" r="1" />
                        <circle cx="19.5" cy="14.5" r="1" />
                        </svg>
                        </Link>
                        </li>

                        <li>
                        <Link to='/' onClick={handleLogout}>
                        <div className='logout-btn'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-logout" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                        <path d="M7 12h14l-3 -3m0 6l3 -3" />
                        </svg>
                        </div>
                        </Link>
                        </li>

                        <li>
                        <Link className='bck-btn' to='/backup'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-database-export" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <ellipse cx="12" cy="6" rx="8" ry="3" />
                            <path d="M4 6v6c0 1.657 3.582 3 8 3a19.84 19.84 0 0 0 3.302 -.267m4.698 -2.733v-6" />
                            <path d="M4 12v6c0 1.599 3.335 2.905 7.538 2.995m8.462 -6.995v-2m-6 7h7m-3 -3l3 3l-3 3" />
                            </svg>
                        </Link>
                        </li>
                        
                        </>
                        
                    }
                    {
                        !usrIn && <li>
                        {/* <Link to='/auth/login'>
                            <img src="/imgs/login.svg" alt="in" />
                        </Link> */}
                        
                        {/* <Link to='/auth/register'>
                            <img src="/imgs/register.svg" alt="signup" />
                        </Link> */}
                    </li>
 
                    }
                    

                </ul>

            </div>
        </nav>
    )
}

export default Nav;