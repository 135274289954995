import React from 'react';
import './Search.css';
import { SearchData, SearchResetData } from '../context/SearchContextReducer/SearchContext';
import Psw from './Psw';
import {Link} from 'react-router-dom';

const Search = () => {

    // CONTEXT SEARCH
    const searchData = React.useContext(SearchData);
    const resetSearchData = React.useContext(SearchResetData);

    // HANDLE RESET SEARCH DATA
    const handleResetSearchData = async () => {
        await resetSearchData();
    };

// RETURN
  return (
    <div className='Search'>
       <h1>Search Results</h1>
       <Link onClick={handleResetSearchData} to='/'>Go Back Home</Link>

       {searchData && searchData.length > 0 && searchData.map(p => (
          <Psw key={p.id} p={p} />
        ))
        }
    </div>
  )
}

export default Search;