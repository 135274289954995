import React from 'react';
import './Profile.css';
import QRCode from 'react-qr-code';
import { Link, useNavigate } from 'react-router-dom';
import { UserInContext } from '../context/LogoutContextReducer/LogoutContext';


const Profile = () => {

    // USE CONTEXT
    const usrIn = React.useContext(UserInContext);

    const history = useNavigate();

    React.useEffect(() => {
        if (!usrIn) {
            history('/');
        }
    }, [history, usrIn]);


    // RETURN
    return (
        <div className='Profile'>
            <h1 className='profile-title'>{usrIn && usrIn?.username} QRCode</h1>

            <h4 className="profile-subtitle">Keep the code secret, scan the qrcode with your auth app</h4>

            <div className="profile-container">

                <div className="profile-qrcode-wrapper">

                    {!usrIn === '' ? <img src="/qr.placeholder.png" alt="qrcode" />
                        :

                        <QRCode
                            size={200}
                            style={{ height: "auto", maxWidth: "300px", width: "100%", margin: '20px auto' }}
                            value={usrIn && usrIn?.otpauthurl}
                            viewBox={`0 0 200 200`}
                        />

                    }

                </div>
                <details className='profile-details'>
                    <summary>Secret Key</summary>
                    <small className="profile-code">{usrIn && usrIn?.secret}</small>
                </details>

                <div className="profile-reset-wrapper">
                    <button className='profile-reset-btn'>
                        <Link to={`/auth/reset/${usrIn?.email}`}>Reset Password</Link>
                    </button>
                </div>


                <div className="profile-home-wrapper">
                    <button className='profile-home-btn'>
                        <Link to='/'>Home Page</Link>
                    </button>
                </div>
            </div>

        </div>
    )
}


export default Profile;