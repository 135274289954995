import React from "react";
import generatePswReducer from "./GeneratePswReducer";

const GenerateContext = React.createContext();
export const GenerateLengthContext = React.createContext();
export const GenerateUpperContext = React.createContext();
export const GenerateNumContext = React.createContext();
export const GenerateSymContext = React.createContext();
export const GenerateLowerContext = React.createContext();
export const GenerateDataContext = React.createContext();
//  SET 
export const GenerateSetLengthContext = React.createContext();
export const GenerateSetUpperContext = React.createContext();
export const GenerateSetNumContext = React.createContext();
export const GenerateSetSymContext = React.createContext();
export const GenerateSetDataContext = React.createContext();

export const GenerateProvider = ({children})=>{

    // INITIAL STATE
    const initialState = {
        pswLength: 13,
        upper: true,
        num: true,
        sym: false,
        generated:'',
        lower: true,
    };

    // REDUCER
    const [state, dispatch] = React.useReducer(generatePswReducer, initialState);

    // GENERATE SYMBOL
    let genSym =React.useCallback(()=>{
        let symbols = '@#£%&*-+';
        return symbols[Math.floor(Math.random() * symbols.length)];
    }, []);
    // GEN NUM
    let genNum = React.useCallback(()=>{
        return String.fromCharCode(Math.floor(Math.random() * 10) + 48);
    }, []);
    // GEN UPPER
    let genUp = React.useCallback(()=>{
        return String.fromCharCode(Math.floor(Math.random() * 26) + 65);
    },[]);
    // GEN LOWER
    let genLower = React.useCallback(()=>{
        return String.fromCharCode(Math.floor(Math.random() * 26) + 97);
    },[]);


    // GENERATE PASSWORD
    let genPswFn = React.useCallback(()=>{
        // Empty Pass
        let psw = '';
        // DISPATCH RESET
        dispatch({type: 'PSW_GENERATED', psw: ''});
        
        // Type Count
        let typesCount = state.lower + state.upper + state.num + state.sym;
        let typeArr = [{lower: state.lower},{upper: state.upper},{num: state.num}, {sym:state.sym}].filter(i => Object.values(i)[0]);
        // console.log(typeArr)
        // console.log(typesCount);
        // console.log(genSym());
        // console.log(genNum());
        // console.log(genUp());
        // console.log(genLower());

            // Random function
        const randomFunGen =  {
            lower: genLower,
            upper: genUp,
            num: genNum,
            sym: genSym
        };

        for(let i = 0; i < state.pswLength; i+= typesCount){
            typeArr.forEach(type => {
                let fnName = Object.keys(type)[0];
                psw += randomFunGen[fnName]();
            });
        }
        //
        let finalPsw = psw.slice(0, state.pswLength);
        // console.log(finalPsw);
        // DISPATCH GENERATED PASSWORD
        dispatch({type: 'PSW_GENERATED', psw: finalPsw});

        // COPY
        let copyPsw = () => {navigator.clipboard.writeText(finalPsw)}
        copyPsw();
    }, [dispatch, state.pswLength, state.lower, state.upper, state.num, state.sym, genSym, genNum, genUp, genLower]);
    
    // DISPATCH PASSWORD LENGTH
    let setPswLength = React.useCallback((length)=>{
        dispatch({type: 'PSW_LENGTH', pswLength: length});
    }, [dispatch]);
    // DISPATCH UPPER
    let setUpper = React.useCallback((upper)=>{
        dispatch({type: 'PSW_UPPER', upper: upper});
    }, [dispatch]);
    // DISPATCH NUM
    let setNum = React.useCallback((num)=>{
        dispatch({type: 'PSW_NUM', num: num});
    }, [dispatch]);
    // DISPATCH SYM
    let setSym = React.useCallback((sym)=>{
        dispatch({type: 'PSW_SYM', sym: sym});
    }, [dispatch]);

    // RETURN
    return (
        <GenerateContext.Provider value={genPswFn}>
            <GenerateLengthContext.Provider value={state.pswLength}>
                <GenerateSetLengthContext.Provider value={setPswLength}>
                    <GenerateUpperContext.Provider value={state.upper}>
                        <GenerateSetUpperContext.Provider value={setUpper}>
                            <GenerateNumContext.Provider value={state.num}>
                                <GenerateSetNumContext.Provider value={setNum}>
                                    <GenerateSymContext.Provider value={state.sym}>
                                        <GenerateSetSymContext.Provider value={setSym}>
                                            <GenerateDataContext.Provider value={state.generated}>
                                                <GenerateLowerContext.Provider value={state.lower}>
            {children}                      
                                                </GenerateLowerContext.Provider>
                                            </GenerateDataContext.Provider> 
                                        </GenerateSetSymContext.Provider>
                                    </GenerateSymContext.Provider>
                                </GenerateSetNumContext.Provider>
                            </GenerateNumContext.Provider>
                        </GenerateSetUpperContext.Provider>
                    </GenerateUpperContext.Provider>
                </GenerateSetLengthContext.Provider>
            </GenerateLengthContext.Provider>
        </GenerateContext.Provider>
    )
};

export default GenerateContext;


