import React from 'react';
import './Backup.css';
import PswsGetAllContext, { PswsGetAllLoading, PswsGetAllError, PswsGetAllData } from '../context/PswsGetAllContextReducer/PswsGetAllContext';
import { BsHourglassSplit } from 'react-icons/bs';
import { UserInContext } from '../context/LogoutContextReducer/LogoutContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import UploadBck from './UploadBck';
import BckDownloadContext, { BckDownloadLoading, BckDownloadError } from '../context/BackupContextReducer/BckDownloadContext';


const Backup = () => {

  // NAVIGATE
  const navigate = useNavigate();

  // CONTEXT PSWS GET ALL
  const fetchAllPswsFn = React.useContext(PswsGetAllContext);
  const pswsGetAllLoading = React.useContext(PswsGetAllLoading);
  const pswsGetAllError = React.useContext(PswsGetAllError);
  const pswsGetAllData = React.useContext(PswsGetAllData);
  // const pswsGetAllResetFn = React.useContext(PswsGetAllResetData);

  // console.log(pswsGetAllData)


  // BCK DOWNLOAD
  const bckDownloadFn = React.useContext(BckDownloadContext);
  const bckDownloadLoading = React.useContext(BckDownloadLoading);
  const bckDownloadError = React.useContext(BckDownloadError);
  // console.log(bckDownloadLoading);
  // console.log(bckDownloadError);

  // CONTEXT USER IN SET
  const usrIn = React.useContext(UserInContext);


  // BACKUP ERROR
  React.useEffect(() => {
    if (bckDownloadError !== '') {
      toast.error(bckDownloadError);
    }
  }, [bckDownloadError]);


  // CHECK IF USR IN
  React.useEffect(() => {
    if (usrIn == null) {
      navigate('/');
    }
  }, [usrIn, navigate]);


  React.useEffect(() => {

    (async () => await fetchAllPswsFn())();
    // setTimeout(() => {
    //   (async () => await pswsGetAllResetFn())();
    // }, 180000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // BACKUP ERROR
  React.useEffect(() => {
    if (pswsGetAllError !== '') {
      toast.error(pswsGetAllError);
    }
  }, [pswsGetAllError]);

  // BCK PRINT
  const bckPrint = React.useCallback(() => {
    window.print();
  }, []);

  // HANDLE DOWNLOAD
  const handleDownload = async () => {
    bckDownloadFn();
  };


  // RETURN
  return (
    <div className='Backup'>
      <h1>Backup</h1>

      <div className="bck-controls">

        {/* PRINT  */}
        <button onClick={bckPrint}><img src="/imgs/print.svg" alt="print" /> <span>Print</span></button>

        {/* DOWNLOAD PASSWORD     */}
        <button onClick={handleDownload}><img src="/imgs/backup.svg" alt="download" /><span>Download</span></button>

        {/* UPLOAD FILE  */}
        <UploadBck />

      </div>

      {pswsGetAllLoading && <div className="bck-loading"><BsHourglassSplit /></div>}

      {bckDownloadLoading && <div className="bck-loading"><BsHourglassSplit /></div>}


      <div className='bck-results'>

        <table className="tg">
          <thead>
            <tr>
              <th className="tg-0lax">Site</th>
              <th className="tg-0lax">User</th>
              <th className="tg-0lax">Psw</th>
              <th className="tg-0pky">Date</th>
            </tr>
          </thead>
          <tbody>
            {
              pswsGetAllData && pswsGetAllData.length > 0 && pswsGetAllData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="tg-0lax">{item.site}</td>
                    <td className="tg-0lax">{item.user}</td>
                    <td className="tg-0lax">{item.psw}</td>
                    <td className="tg-0pky">{(new Date(item.created_at)).toLocaleDateString('it')}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Backup;