import './Footer.css';
import PrivacyModal from './PrivacyModal';

const Footer = () => {

    let today = new Date();

    // RETURN 
    return (
        <footer className='Footer'>
            <div className='footer-privacy'>
                <PrivacyModal className='privacy-btn' />
            </div>
            <small>Pa Man&copy; {today.getFullYear()}</small>
        </footer>
    )
}

export default Footer;
