import React from 'react';
import darkReducer from './DarkReducer';

const DarkContext = React.createContext();
export const DarkSetContext = React.createContext();

export const DarkProvider = ({children}) =>{

    let checkDark = false;
    let isDark = JSON.parse(localStorage.getItem('dark'));
    let darkTheme = window.matchMedia("(prefers-color-scheme:dark)");
    // console.log(darkTheme.matches);
    // console.log(isDark);
    if(isDark){
        // set initial state dark
        // console.log('checkin local storage true')
       checkDark = isDark;
    }else if(isDark === false){
        // console.log('checkin local storage false')
        checkDark = isDark;
    }else{
        // set theme based on os color schema
        // console.log('checking theme')
        checkDark = darkTheme.matches;
    }
   

    // INITIAL STATE
    const initialState = {
        isDark: checkDark,
    };

    // REDUCER
    const [state, dispatch] = React.useReducer(darkReducer, initialState);

    // SET DARK FUNCTION
    const setDarkFn = React.useCallback((type) => {
        dispatch({type: type});
    }, [dispatch]);

    // USE EFFECT

    React.useEffect(()=>{
 
        if(state.isDark){
            setDarkFn('SET_DARK');
        }else{
            setDarkFn('SET_LIGHT');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // RETURN
    return (
        <DarkContext.Provider value={state.isDark}>
            <DarkSetContext.Provider value={setDarkFn}>
            {children}
            </DarkSetContext.Provider>
        </DarkContext.Provider>
    )
}

export default DarkContext;