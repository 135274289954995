import './Psw.css';
import React from 'react';
import { Link } from 'react-router-dom';
import sanitizeInput from '../hooks/sanitizeInput';

const Psw = ({ p }) => {
    // console.log(typeof p);
    const [values, setValues] = React.useState({
        site: '',
        user: '',
        psw: ''
    });

    // Handle Change
    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: sanitizeInput(value) });
    }

    // USE EFFECT

    React.useEffect(() => {
     
            if (p) {
                
                setValues({
                    site: p.site,
                    user: p.user,
                    psw:''
                })
            }

        // eslint-disable-next-line
    }, []);

    // RETURN
    return (
        <div className='Psw'>

            <div className="pswCard">
                <div className="controls">
                    {/* <div className='pswCardEdit' onClick={handleEdit}><img src="/imgs/edit.svg" alt="edit" />
                    </div> */}
                    {/* <div className='pswCardDel' onClick={handleDelete}>
                        <img src="/imgs/trash.svg" alt="trash" />
                    </div> */}
                    <div className='pswCardLink'>
                        <Link to={`/pswpage/${p.id}`}>
                            <button className='pswCardLink-btn'>Show</button>
                        </Link>
                    </div>
                </div>
                <form autoComplete='off' onSubmit={e => e.preventDefault()}>
                    <div>
                        <label htmlFor="site">SITE</label>
                        <input
                            name='site'
                            id={`site-${p.id}`}
                            type="text"
                            value={values.site}
                            onChange={e => handleChange(e)}
                            disabled
                        />
                    </div>
                    <div>
                        <label htmlFor="user">USER</label>
                        <input
                            type="text"
                            name="user"
                            id={`user-${p.id}`}
                            value={values.user}
                            onChange={e => handleChange(e)}
                            disabled
                        />

                    </div>
                    {/* <div>
                        <label htmlFor="psw">Pass</label>
                        <input
                            id={`psw-${p.id}`}
                            name='psw'
                            type="text"
                            value={values.psw}
                            onChange={e => handleChange(e)}
                            disabled
                        />
                    </div> */}
                </form>

            </div>
            <hr />
        </div>
    )
}

export default React.memo(Psw);
