import React from 'react';
import api from '../../config/api';
import Cookies from 'universal-cookie';
import pswsGetAllReducer from './PswsGetAllReducer';

const PswsGetAllContext = React.createContext({});
export const PswsGetAllLoading = React.createContext(false);
export const PswsGetAllError = React.createContext(null);
export const PswsGetAllData = React.createContext();
export const PswsGetAllResetData = React.createContext();


export const PswsGetAllProvider = ({ children }) => {

    // INITIAL STATE
    const initialState = React.useMemo(() => {
        return {
        pswsGetAllLoading: false,
        pswsGetAllError: '',
        pswsGetAllData: [],
        };
    },[]);

    // REDUCER
    const [state, dispatch] = React.useReducer(pswsGetAllReducer, initialState);
 
  
    // FETCH ALL PSWORDS
    const fetchAllPswsFn = React.useCallback( async () => {
    
        // GET COOKIE
        const cookies = new Cookies();  
 
        // TOKEN
        let tkn = cookies.get('tkn');
        // console.log(tkn);
        if(tkn === undefined) return;

        try {
            if(tkn){
                // DISPATCH LOADING
                dispatch({type: 'PSWS_GET_ALL_LOADING', loading: true}); 
    
                // Config
                let config = {
                    headers:{
                        'Content-Type':'application/json',
                        Authorization: `Bearer ${tkn}`
                    }
                };
    
                const res = await api.get(`/api/psws/all`, config);
                // console.log(res)

                // DISPATCH DATA
                dispatch({type: 'PSWS_GET_ALL_DATA', data: res?.data?.arrSorted});
            };

        } catch (err) {
            if (err?.response) {
                console.log(err?.response?.data?.error);
                // console.log(err?.response?.status);
                // console.log(err?.response?.headers);
                
                // DISPATCH ERROR
                dispatch({type: 'PSWS_GET_ALL_ERROR', error: err?.response?.data?.error === "jwt malformed" ? '' : err?.response?.data?.error});

            } else {
                console.log(err.message);
                dispatch({type: 'PSWS_GET_ALL_ERROR', error: err?.message});
            }
        } finally {
            // DISPATCH LOADING
            dispatch({type: 'PSWS_GET_ALL_LOADING', loading: false});

            setTimeout(()=>{
                dispatch({type: 'PSWS_GET_ALL_ERROR', error: ''});
            }, 3000);
        }
    }, [dispatch]);


    // PSWS GET ALL RESET
    const pswsGetAllResetFn = React.useCallback( () => {
        dispatch({type: 'PSWS_GET_ALL_RESET', reset: initialState});
    }, [dispatch, initialState]);

    // RETURN 
    return (
        <PswsGetAllContext.Provider value={fetchAllPswsFn}>
            <PswsGetAllLoading.Provider value={state.pswsGetAllLoading}>
                <PswsGetAllError.Provider value={state.pswsGetAllError}>
                    <PswsGetAllData.Provider value={state.pswsGetAllData}>
                     <PswsGetAllResetData.Provider value={pswsGetAllResetFn}>
                            {children}
                        </PswsGetAllResetData.Provider>
                    </PswsGetAllData.Provider>
                </PswsGetAllError.Provider>
            </PswsGetAllLoading.Provider>
        </PswsGetAllContext.Provider>
    )
}

export default PswsGetAllContext;