import "./PswPage.css";
import { useParams } from "react-router-dom";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import PswsGetAllContext, {PswsGetAllLoading} from '../context/PswsGetAllContext';
import PswGetSingleContext, {
  PswGetSingleData,
  PswGetSingleLoading,
  PswGetSingleError,
  PswGetSingleReset,
} from "../context/PswGetSingleContextReducer/PswGetSingleContext";
import PswsGetPageContext from "../context/PswsGetPageContextReducer/PswsGetPageContext";
import PswUpdateContext, {
  PswUpdateLoading,
  PswUpdateData,
} from "../context/PswUpdateContextReducer/PswUpdateContext";
import PswDeleteContext, {
  PswDeleteLoading,
  PswDeleteError,
  PswDeleteSuccess,
} from "../context/PswDeleteContextReducer/PswDeleteContext";
import PswGetPswContext, {
  PswGetPswData,
  PswGetPswLoading,
  PswGetPswError,
} from "../context/PswGetPswContextReducer/PswGetPswContext";
import { UserInContext } from "../context/LogoutContextReducer/LogoutContext";
import { BsHourglassSplit } from "react-icons/bs";
import sanitizeInput from "../hooks/sanitizeInput";
import { toast } from "react-toastify";

const PswPage = () => {
  // ID
  const { id } = useParams();
  // console.log(id);

  // HISTORY
  const history = useNavigate();

  // VALUES
  const [values, setValues] = React.useState({
    site: "",
    user: "",
    psw: "",
  });

  // CONSTE SINGLE PSW
  const pswFetchSingleFn = React.useContext(PswGetSingleContext);
  const pswGetSingleLoading = React.useContext(PswGetSingleLoading);
  const pswGetSingleData = React.useContext(PswGetSingleData);
  const pswGetSingleError = React.useContext(PswGetSingleError);
  const pswGetSingleReset = React.useContext(PswGetSingleReset);

  // LOGOUT CONTEXT
  const usrIn = React.useContext(UserInContext);

  // USE EFFECT CHECK USER
  React.useEffect(() => {
    if (usrIn === null) {
      history("/");
    }
  }, [usrIn, history]);

  // FETCH SINCLE ERROR USE EFFECT
  React.useEffect(() => {
    if (pswGetSingleError) {
      toast.error(pswGetSingleError);
      history("/");
    }
  }, [pswGetSingleError, history]);

  // CONTEXT PSWS GET PAGE
  const fetchPsws = React.useContext(PswsGetPageContext);

  // FETCH GET PASSWORD
  const pswFetch = React.useContext(PswGetPswContext);
  const pswGetPswLoading = React.useContext(PswGetPswLoading);
  const pswGetPswError = React.useContext(PswGetPswError);
  const pswGetPswData = React.useContext(PswGetPswData);

  // PSW CONTEXT UPDATE
  const pswUpdateFn = React.useContext(PswUpdateContext);
  const pswUpdateLoading = React.useContext(PswUpdateLoading);
  const pswUpdateData = React.useContext(PswUpdateData);

  // PSW CONTEXT DELETE
  const pswDeleteFn = React.useContext(PswDeleteContext);
  const pswDeleteLoading = React.useContext(PswDeleteLoading);
  const pswDeleteError = React.useContext(PswDeleteError);
  const pswDeleteSuccess = React.useContext(PswDeleteSuccess);

  // CONTEXT PSWS GET ALL
  //  const fetchAllPswsFn = React.useContext(PswsGetAllContext);
  // const pswsGetAllLoading = React.useContext(PswsGetAllLoading);

  // USE EFFECT UPDATE PSW
  React.useEffect(() => {
    if (pswUpdateData) {
      toast.success(pswUpdateData?.message);
    }
  }, [pswUpdateData]);

  // USE EFFECT FETCH
  React.useEffect(() => {
    if (usrIn !== null) {
      // RESET
      pswGetSingleReset();
      // FETCH FRESH DATA ON LOAD
      (async () => pswFetchSingleFn(id))();
    }

    // eslint-disable-next-line
  }, [id, usrIn]);

  //* USE EFFECT ADD DATA
  React.useEffect(() => {
    if (id && pswGetSingleData) {
      setValues({
        site: pswGetSingleData.site,
        user: pswGetSingleData.user,
        psw: pswGetSingleData.psw,
      });
    }

    // eslint-disable-next-line
  }, [pswGetSingleData]);

  // HANDLE EDIT
  const handleEdit = async () => {
    // console.log(id, values);
    let sure = window.confirm("Are you sure?");

    if (sure) {
      // console.log(values)
      let res = await pswUpdateFn(id, values);
      if (res) {
        // console.log(res);
        await pswFetchSingleFn(id);
        await fetchPsws();
        toast.success(res.message);
      }
    }
  };

  //* HANDLE DELETE
  const handleDelete = async () => {
    // console.log(p.id);
    let sure = window.confirm("Are you sure?");
    if (sure) {
      await pswDeleteFn(id);
    }
  };

  // DELETE SUCCESS
  React.useEffect(() => {
    if (pswDeleteSuccess !== "") {
      // console.log(pswDeleteSuccess);
      toast.success(pswDeleteSuccess);
      // RESET SINGLE STATE
      pswGetSingleReset();
      // FETCH ALL PSWS
      (async () => await fetchPsws())();
      // REDIRECT
      history("/");
    }
  }, [pswDeleteSuccess, fetchPsws, pswGetSingleReset, history]);

  // DELETE ERROR
  React.useEffect(() => {
    if (pswDeleteError) {
      toast.error(pswDeleteError);
    }
  }, [pswDeleteError]);

  // HANDLE UNLOCK PASSWORD
  const handleUnLock = async (id) => {
    // console.log(id);
    let sure = window.confirm("Are you sure?");
    if (sure) {
      await pswFetch(id);
    }
  };

  //* USE EFFECT COPY PASSWORD TO THE CLIPBOARD
  React.useEffect(() => {
    if (pswGetPswData !== "") {
      // console.log(pswGetPswData);
      // console.log('Password copied');
      toast.success("Password copied.");

      navigator.clipboard.writeText(pswGetPswData);

      setValues({ ...values, psw: pswGetPswData });

      setTimeout(() => {
        setValues({ ...values, psw: "" });
      }, 10000);

      // return () => clearTimeout(timePsw);
    }
    // eslint-disable-next-line
  }, [pswGetPswData]);

  // PSW GET ERROR
  React.useEffect(() => {
    if (pswGetPswError) {
      toast.error(pswGetPswError);
    }
  }, [pswGetPswError]);

  // COPY USER
  const copyUser = async () => {
    navigator.clipboard.writeText(values.user);
  };
  // COPY SITE
  const copySite = async () => {
    navigator.clipboard.writeText(values.site);
  };

  // RETURN
  return (
    <main className="PswPage">
      {pswGetSingleLoading && !pswGetSingleData ? (
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      ) : (
        <>
          <div className="pswWrap">
            <h1 className="pswPageTitle">Edit</h1>

            <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
              <div className="pswSite">
                <label htmlFor="site">SITE</label>
                <input
                  type="text"
                  id="site"
                  name="site"
                  className="site"
                  value={values.site}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      [e.target.name]: sanitizeInput(e.target.value),
                    })
                  }
                  onClick={() => copySite()}
                />
              </div>
              <div className="pswUser">
                <label htmlFor="user">USER</label>
                <input
                  id="user"
                  name="user"
                  className="username"
                  value={values.user}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      [e.target.name]: sanitizeInput(e.target.value),
                    })
                  }
                  onClick={() => copyUser()}
                  type="text"
                />
              </div>
              <div className="pswPsw">
                <label htmlFor="psw">
                  PASSWORD
                  {
                    <span className="psw-loading">
                      {pswGetPswLoading && <BsHourglassSplit />}
                    </span>
                  }
                </label>

                <input
                  id="psw"
                  name="psw"
                  value={values.psw}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      [e.target.name]: sanitizeInput(e.target.value),
                    })
                  }
                  type="text"
                />
              </div>

              <div className="unlocked">{/* {psw !== '' ? psw : null}  */}</div>
            </form>

            <div className="pswControls">
              <div className="pswCardLink">
                <Link onClick={async () => await fetchPsws()} to={`/`}>
                  <img src="/imgs/home.svg" alt="link" />
                </Link>
              </div>
              <div className="pswCardEdit" onClick={handleEdit}>
                {pswUpdateLoading ? (
                  <span className="psw-loading">
                    <BsHourglassSplit />
                  </span>
                ) : (
                  <button className="pswpage-update-btn">Update</button>
                )}
              </div>
              <div className="pswCardUnlock" onClick={() => handleUnLock(id)}>
                {pswGetPswLoading ? (
                  <span className="psw-loading">
                    <BsHourglassSplit />
                  </span>
                ) : (
                  <button className="pswpage-unlock-btn">Unloack</button>
                )}
              </div>
              <div className="pswCardDel" onClick={handleDelete}>
                {pswDeleteLoading ? (
                  <span className="psw-loading">
                    <BsHourglassSplit />
                  </span>
                ) : (
                  <img src="/imgs/trash.svg" alt="delete" />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </main>
  );
};

export default PswPage;
