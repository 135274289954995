
const BckDownloadReducer = (state, action)=>{
    switch(action.type){
        case 'BCK_DOWNLOAD_LOADING':
            return {
                ...state, bckDownloadLoading: action.loading
            }
        case 'BCK_DOWNLOAD_ERROR':
            return {
                ...state, bckDownloadError: action.error
            }
        case 'BCK_DOWNLOAD_DATA':
            return {
                ...state, bckDownloadData: action.data
            }
        // RESET
        case 'BCK_DOWNLOAD_RESET':
            return {
                 ...action.reset 
            }

        // DEFAULT
        default:
            return state;
    };
};

export default BckDownloadReducer;





